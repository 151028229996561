<template>
  <div>
    <OpenForwardedOrders @openOrder="handleOrderClick" class="mb-4 mt-4 mr-4" />
    <v-card
      flat
      class="ma-4 ml-0 rounded-20 overflow-hidden"
      v-if="partialOrders.length > 0"
    >
      <v-card-title class="title-font">
        PARTIALLY COMPLETE ORDERS
      </v-card-title>
      <v-card-text>
        <v-card
          flat
          outlined
          v-for="order in partialOrders"
          :key="order.uid"
          class="rounded-10 pa-2 mb-3"
        >
          <v-row>
            <v-col cols="12">
              <v-icon size="25" class="mr-2">
                {{ determineIconFromProduct(order.product) }}
              </v-icon>
              {{ toTitleCase(order.product) }}
              <v-chip label small color="primary" class="px-1 ml-2">{{
                sessionName(order.session_uid)
              }}</v-chip>
            </v-col>
            <v-col cols="6">
              {{ order.created_at | luxon({ output: "short" }) }}
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                class="btn-error mr-2 pa-2"
                x-small
                @click="deleteOrder(order)"
              >
                Delete
              </v-btn>
              <v-btn
                class="btn-primary mr-2 pa-2"
                x-small
                @click="handleOrderClick(order)"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { toTitleCase, determineIconFromProduct } from "@/utils"
import client from "@/lib/ApiClient"
import { mapGetters, mapActions } from "vuex"
import OpenForwardedOrders from "@/components/shared/OpenForwardedOrders"

export default {
  name: "ExistingAndPartialOrders",

  components: {
    OpenForwardedOrders
  },

  computed: {
    ...mapGetters(["selectedOrder", "orderForwardedOrders"]),

    partialOrders() {
      return this.orderForwardedOrders.filter((o) => !o.submitted_at) || []
    }
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder", "triggerReload"]),

    determineIconFromProduct,

    async handleOrderClick(order) {
      this.setSelectedForwardedOrder(order)
    },

    async deleteOrder(order) {
      await client.instance.orders.deleteForwardedOrder(
        this.selectedOrder.uid,
        order.session_uid,
        order.uid
      )
      this.triggerReload("forwardedOrders")
    },

    toTitleCase,

    sessionName(sessionUid) {
      return toTitleCase(
        this.selectedOrder.sessions.find((s) => s.uid === sessionUid)
          .session_type
      )
    }
  }
}
</script>
