<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font"> SETTINGS </v-toolbar-title>
    </v-toolbar>
    <v-card v-if="!settings" flat class="mx-4 rounded-10 pb-4" align="center">
      <v-progress-circular indeterminate class="mt-4" color="primary" />
    </v-card>
    <v-card v-if="settings" flat class="mx-4 rounded-10 pb-4">
      <v-card-text class="black--text">
        <v-row>
          <v-col cols="12">
            <v-row align="center" class="text-subtitle-2 px-5">
              <v-icon class="mr-2">mdi-account-group</v-icon>
              Manage Clients
              <v-spacer />
              <v-switch v-model="settings.manage_clients" inset />
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row align="center" class="text-subtitle-2 px-5">
              <v-icon class="mr-2"> $surgicalTool </v-icon>
              Immediate Workflow
              <v-spacer />
              <v-switch v-model="settings.immediate_workflow" inset />
            </v-row>
          </v-col>

          <template>
            <v-col cols="12">
              <v-row align="center" class="text-subtitle-2 px-5 py-4">
                <v-icon class="mr-2">mdi-web</v-icon>
                Ordering Website
                <v-spacer />
                <v-text-field
                  v-model="slug"
                  :prefix="orderingBaseURI"
                  outlined
                  :hide-details="!!!slugErrorMessage"
                  :error-messages="slugErrorMessage"
                  dense
                />
              </v-row>
            </v-col>
          </template>

          <template v-if="featureFlags.custom_branding">
            <v-col cols="12">
              <v-row align="center" class="text-subtitle-2 px-5 py-4">
                <v-icon class="mr-2"> mdi-palette </v-icon>
                Theme
                <v-spacer />
                <v-btn class="btn-primary" @click="themeDialog = true">
                  Customise Theme
                </v-btn>
                <v-dialog
                  persistent
                  v-model="themeDialog"
                  width="fit-content"
                  content-class="elevation-0"
                >
                  <ThemePreview
                    v-model="settings"
                    :savedColours="savedSettings.colours"
                    @close="themeDialog = false"
                  />
                </v-dialog>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row align="center" class="text-subtitle-2 px-5 py-3">
                <v-icon class="mr-2">mdi-panorama-variant</v-icon>
                Custom Logo
                <v-spacer />
                <v-file-input
                  :label="
                    logoUrl ? 'Upload a new Logo' : 'Upload your Business Logo'
                  "
                  width="300px"
                  full-width
                  outlined
                  dense
                  hide-details
                  :clearable="false"
                  v-model="logoFile"
                  @change="handleLogoUpload"
                />
                <v-btn
                  v-if="logoUrl"
                  class="btn-error ml-3"
                  @click="deleteLogo()"
                  :loading="loading"
                >
                  DELETE
                </v-btn>
                <v-btn
                  v-if="logoUrl"
                  class="btn-primary ml-3"
                  @click="logoPreview = true"
                >
                  Preview
                </v-btn>
              </v-row>
              <v-dialog v-model="logoPreview" max-width="500">
                <div class="white pa-2">
                  <v-img
                    max-width="500"
                    max-height="500"
                    contain
                    :src="logoUrl"
                  />
                </div>
              </v-dialog>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-actions class="mx-2">
      <v-spacer />
      <v-btn
        text
        color="error"
        disabled
        class="rounded-10"
        v-if="unsavedChanges"
      >
        <v-icon class="mr-2">mdi-alert</v-icon>
        Unsaved Changes
      </v-btn>
      <v-btn class="btn-primary" @click="save" :disabled="loading || !settings">
        <span v-if="!loading">Save</span>
        <v-progress-circular v-if="loading" indeterminate />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { debounce } from "@/utils"
import { mapActions, mapGetters } from "vuex"
import ThemePreview from "./Settings/ThemePreview"
import client from "@/lib/ApiClient"
import { deepEqual, deepCopy } from "@/utils"

export default {
  name: "SettingsCard",

  components: {
    ThemePreview
  },

  data() {
    return {
      loading: false,
      settings: null,
      logoUrl: null,
      logoFile: null,
      themeDialog: false,
      logoPreview: false,
      slug: null,
      slugAvailable: true
    }
  },

  watch: {
    savedSettings(val, prevVal) {
      if (val && prevVal === null) this.initialise()
    },

    slug(slug) {
      if (!slug) return
      this.debouncedSlugCheck()
    }
  },

  computed: {
    ...mapGetters({
      savedSettings: "settings",
      reloadQueue: "reloadQueue",
      featureFlags: "featureFlags",
      orderingBaseURI: "orderingBaseURI",
      tenant: "currentTenant"
    }),

    unsavedChanges() {
      if (this.settings === null) return false
      if (this.tenant.slug !== this.slug) return true
      return !deepEqual(this.settings, this.savedSettings)
    },

    slugErrorMessage() {
      if (!this.slug) return "Cannot be empty"
      return this.slugAvailable ? null : "This one's already taken"
    }
  },

  methods: {
    ...mapActions({
      triggerReload: "triggerReload",
      setSavedSettings: "setSettings"
    }),

    async save() {
      if (this.slugErrorMessage) return
      this.loading = true
      await client.instance.tenants.updateSettings(this.settings)
      if (this.tenant.slug !== this.slug) {
        const existingTenant =
          await client.gateway.tenants.fetchTenantInformation()
        await client.gateway.tenants.updateTenantInformation({
          ...existingTenant,
          slug: this.slug
        })
        window.location.reload()
        return
      }
      await this.fetch()
      this.loading = false
    },

    async fetch() {
      this.loading = true
      const response = await client.instance.tenants.fetchSettings()
      this.setSavedSettings(response)
      this.settings = deepCopy(this.savedSettings)
      this.loading = false
    },

    async handleSlugCheck() {
      if (this.slug === this.tenant.slug) {
        this.slugAvailable = true
        return
      }
      const result = await client.gateway.tenants.checkSlug(this.slug)
      this.slugAvailable = !result
    },

    async handleLogoUpload(file) {
      if (!file) return
      this.loading = true

      const oftResponse = await client.oft.uploadFiles(file)

      const payload = {
        oft_file_uid: oftResponse.oftUid,
        file_name: oftResponse.oftFileName
      }
      await client.instance.tenants.uploadLogo(payload)

      this.settings.logo_file_name = oftResponse.oftFileName
      this.settings.logo_oft_file_uid = oftResponse.oftUid

      this.fetchLogoUrl()
      this.loading = false
    },

    async fetchLogoUrl() {
      if (!this.settings.logo_oft_file_uid) return

      const response = await client.oft.getFileUrls({
        oft_file_uid: this.settings.logo_oft_file_uid
      })
      this.logoUrl = response.url
    },

    async deleteLogo() {
      this.loading = true
      await client.instance.tenants.deleteLogo()
      await this.fetch()
      this.logoUrl = null
      this.logoFile = null
      this.loading = false
    },

    initialise() {
      this.settings = deepCopy(this.savedSettings)

      this.fetchLogoUrl()
      this.loading = false
    }
  },

  mounted() {
    this.slug = this.tenant.slug
  },

  beforeMount() {
    if (this.savedSettings) this.initialise()
  },

  created() {
    this.debouncedSlugCheck = debounce(this.handleSlugCheck, 500)
  }
}
</script>
