<template>
  <v-card flat class="rounded-20 pb-4" v-if="openOrders.length">
    <v-card-title class="title-font pb-0"> OPEN OSTEON ORDERS </v-card-title>
    <v-card
      outlined
      v-for="(forwardedOrder, index) in openOrders"
      :key="index"
      flat
      class="rounded-10 d-flex flex-column mx-4 mt-2"
    >
      <v-card-title class="title-font pb-0">
        <v-icon size="30" class="mr-2">
          {{ determineIconFromProduct(forwardedOrder.product) }}
        </v-icon>
        {{ toTitleCase(forwardedOrder.product) }}
        <v-chip label small color="primary" class="px-1 ml-2">{{
          sessionName(forwardedOrder.session_uid)
        }}</v-chip>
        <v-spacer />
        <v-tooltip top class="rounded-10">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openForwardedOrder(forwardedOrder)"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-arrow-expand-all </v-icon>
            </v-btn>
          </template>
          <span>Open Osteon Order</span>
        </v-tooltip>
      </v-card-title>

      <v-row>
        <v-col cols="6" class="pr-0">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> OSTEON CASE REFERENCE </v-list-item-title>
                <v-list-item-subtitle>
                  {{ forwardedOrder.case_details[0].case_mkey }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> STATUS </v-list-item-title>
                <v-list-item-subtitle>
                  {{ forwardedOrder.case_details[0].status }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6" class="pl-0">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> SUBMITTED </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    forwardedOrder.submitted_at
                      | luxon({ output: "date_short" })
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> DUE DATE </v-list-item-title>
                <v-list-item-subtitle
                  v-if="forwardedOrder.case_details[0].due_date"
                >
                  {{ forwardedOrder.case_details[0].due_date }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="!forwardedOrder.case_details[0].due_date"
                >
                  Not Available
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { toTitleCase, determineIconFromProduct } from "@/utils"
import { mapGetters } from "vuex"

export default {
  name: "OpenForwardedOrders",

  computed: {
    ...mapGetters(["orderForwardedOrders", "selectedOrder"]),

    openOrders() {
      return (
        this.orderForwardedOrders
          ?.filter((o) => !!o.submitted_at)
          .sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          ) || []
      )
    }
  },

  methods: {
    toTitleCase,

    determineIconFromProduct,

    openForwardedOrder(forwardedOrder) {
      this.$emit("openOrder", forwardedOrder)
    },

    sessionName(sessionUid) {
      return toTitleCase(
        this.selectedOrder.sessions.find((s) => s.uid === sessionUid)
          .session_type
      )
    }
  }
}
</script>
