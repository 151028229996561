<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row>
      <v-col xl="6" lg="6" md="12" sm="12" class="py-0">
        <v-card
          flat
          class="card-bg rounded-20 mb-5"
          v-if="currentTenant && currentTenant.role !== 'owner'"
        >
          <v-toolbar class="transparent" flat dense>
            <v-toolbar-title class="title-font">
              SETTINGS RESTRICTED
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-card flat class="rounded-10">
              <v-card-text>
                <p class="mb-0">
                  You are not the designated owner of this business. You are
                  unable to make changes to account settings.
                </p>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <AuthorizedUsers class="mb-5" v-if="currentTenant?.role === 'owner'" />
        <SubscriptionInfo
          class="mb-5"
          v-if="subscription && currentTenant.role === 'owner'"
        />
        <QuotaOverviewCard class="mb-5" v-if="subscription" />
        <QuotaHistory v-if="subscription && currentTenant.role === 'owner'" />
      </v-col>
      <v-col xl="6" lg="6" md="12" sm="12" class="py-0">
        <TenantDetails
          class="mb-5"
          v-if="subscription && currentTenant.role === 'owner'"
        />
        <Settings
          class="mb-5"
          v-if="subscription && currentTenant.role === 'owner'"
        />
        <PaymentMethods class="mb-5" v-if="currentTenant?.role === 'owner'" />
        <InvoiceHistory v-if="subscription && currentTenant.role === 'owner'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthorizedUsers from "./ProfilePage/AuthorizedUsers"
import SubscriptionInfo from "./ProfilePage/SubscriptionInfo"
import QuotaOverviewCard from "./ProfilePage/QuotaOverviewCard"
import PaymentMethods from "./ProfilePage/PaymentMethods"
import InvoiceHistory from "./ProfilePage/InvoiceHistory"
import TenantDetails from "./ProfilePage/TenantDetails.vue"
import Settings from "./ProfilePage/Settings.vue"
import QuotaHistory from "./ProfilePage/QuotaHistory.vue"

import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "ProfilePage",

  components: {
    AuthorizedUsers,
    SubscriptionInfo,
    QuotaOverviewCard,
    PaymentMethods,
    InvoiceHistory,
    QuotaHistory,
    TenantDetails,
    Settings
  },

  computed: {
    ...mapGetters(["currentTenant", "subscription"])
  },

  methods: {
    ...mapActions(["setSubscription"]),

    async reloadSubscription() {
      const subscriptionResponse =
        await client.gateway.billing.subscriptionInformation()
      this.setSubscription(subscriptionResponse)
    }
  },

  mounted() {
    this.reloadSubscription()
  }
}
</script>
