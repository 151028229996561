<template>
  <div>
    <v-card flat class="card-bg">
      <v-card flat class="ma-4 rounded-20">
        <v-card-title class="title-font">
          ORDER FORM
          <v-spacer />
          <v-btn class="my-auto" icon :to="{ name: 'inboxPage' }">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card flat class="ma-4 rounded-20">
        <v-form ref="orderForm">
          <v-card-text align="center" v-if="initialising">
            <LoadingSpinner />
          </v-card-text>
          <v-card-text v-if="!initialising">
            <v-row v-if="settings.manage_clients">
              <v-col>
                <v-autocomplete
                  v-model="form.client_uid"
                  :items="clientItems"
                  outlined
                  dense
                  label="Client (Required)"
                  :rules="rule"
                  :menu-props="{ rounded: '10' }"
                />
              </v-col>
              <v-col cols="auto">
                <v-btn class="btn-primary" text @click="newClientDialog = true">
                  New
                </v-btn>
                <ClientModal
                  v-model="newClientDialog"
                  :client="newClient"
                  form-title="New Client"
                  :loading="loading"
                  @close="closeNewClientModal"
                  @save="saveNewClient"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-if="!settings.manage_clients"
              v-model="form.client_name"
              outlined
              dense
              label="Client (Required)"
              :rules="rule"
            />
            <v-text-field
              v-model="form.patient_reference"
              outlined
              dense
              label="Patient Reference (Required)"
              :rules="rule"
            />

            <NewOrderHasGaugeScans v-model="form.gauge_scans" />

            <ScanGaugeKitSelector
              v-if="form.gauge_scans"
              v-model="form.kit_box_id"
            />

            <NewOrderCaseType
              v-model="form.immediate"
              v-if="settings.immediate_workflow"
            />

            <template v-if="scanGaugeKits?.length">
              <NewOrderScanUploadAndValidate
                v-show="form.gauge_scans"
                :loading="loading"
                :sessionId="ncSessionId"
                :leftToRight="leftToRightScan"
                :rightToLeft="rightToLeftScan"
                :validateScansRes="validateScansResponse"
                :immediate="form.immediate"
                :skip-validation="skipValidation"
                @update="updateInstance"
              />

              <AdditionalFileUploader
                :scanFiles="scanFileUploads"
                :photoFiles="photoFileUploads"
                :otherFiles="otherFileUploads"
                @updateFiles="updateInstance"
              />

              <NewOrderOrderForm v-model="form" />
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="btn-error"
              :to="{ name: 'inboxPage' }"
              :disabled="loading || initialising"
            >
              Close
            </v-btn>
            <v-btn
              text
              class="ma-2 btn-primary rounded-10"
              @click="createOrder"
              :loading="loading"
              :disabled="loading || initialising || !scanGaugeKits?.length"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card>
    <v-dialog
      v-model="submitting"
      width="350px"
      persistent
      content-class="rounded-20"
    >
      <v-card class="pb-4">
        <v-card-title class="off">
          <h2 class="title-h2 primary--text">PROCESSING</h2>
        </v-card-title>
        <v-card-text class="text-center py-0">
          <LoadingSpinner class="mt-4" />
        </v-card-text>
        <v-card-text class="title-font pb-0 mt-2">File Uploads:</v-card-text>
        <v-card class="rounded-20 mx-4 py-2" flat outlined>
          <v-card-text class="title-font py-2"
            >Scan Gauge Scans:<v-progress-linear
              :color="scanGaugeUploadProgress === 100 ? 'success' : 'primary'"
              :value="scanGaugeUploadProgress"
            ></v-progress-linear
          ></v-card-text>
          <v-card-text class="title-font py-2" v-if="scanFileUploads.length"
            >Scans:<v-progress-linear
              :color="scanUploadProgress === 100 ? 'success' : 'primary'"
              :value="scanUploadProgress"
            ></v-progress-linear
          ></v-card-text>
          <v-card-text class="title-font py-2" v-if="photoFileUploads.length"
            >Photos:<v-progress-linear
              :color="photoUploadProgress === 100 ? 'success' : 'primary'"
              :value="photoUploadProgress"
            ></v-progress-linear
          ></v-card-text>
          <v-card-text class="title-font py-2" v-if="otherFileUploads.length"
            >Other:<v-progress-linear
              :color="otherUploadProgress === 100 ? 'success' : 'primary'"
              :value="otherUploadProgress"
            ></v-progress-linear
          ></v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ClientModal from "@/components/ClientPage/ClientModal.vue"
import ScanGaugeKitSelector from "./NewOrder/ScanGaugeKitSelector.vue"
import AdditionalFileUploader from "./NewOrder/AdditionalFileUploader.vue"
import NewOrderOrderForm from "./NewOrder/NewOrderOrderForm.vue"
import NewOrderScanUploadAndValidate from "./NewOrder/NewOrderScanUploadAndValidate.vue"
import NewOrderCaseType from "./NewOrder/NewOrderCaseType.vue"
import NewOrderHasGaugeScans from "./NewOrder/NewOrderHasGaugeScans.vue"

export default {
  name: "NewOrder",

  components: {
    NewOrderOrderForm,
    ClientModal,
    ScanGaugeKitSelector,
    AdditionalFileUploader,
    NewOrderScanUploadAndValidate,
    NewOrderCaseType,
    NewOrderHasGaugeScans
  },

  data() {
    return {
      initialising: true,
      loading: false,
      submitting: false,
      ncSessionId: null,
      leftToRightScan: null,
      rightToLeftScan: null,
      validateScansResponse: null,
      newClientDialog: false,
      newClient: {},
      form: {
        responses: []
      },
      scanFileUploads: [],
      photoFileUploads: [],
      otherFileUploads: [],
      failedUploads: [],
      scanGaugeUploadProgress: 0,
      scanUploadProgress: 0,
      photoUploadProgress: 0,
      otherUploadProgress: 0,
      rule: [(v) => !!v || "Required"]
    }
  },

  watch: {
    clients(val, prevVal) {
      if (val && this.settings && prevVal === null) {
        this.initialising = false
      }
    },

    settings(val, prevVal) {
      if (val && this.clients && prevVal === null) {
        this.initialising = false
      }
    },

    scanGaugeKits(val) {
      if (val?.length === 0) this.$router.push({ name: "inboxPage" })
      if (val?.length === 1) this.form.kit_box_id = val[0].kit_box_id
    }
  },

  computed: {
    ...mapGetters(["clients", "settings", "scanGaugeKits", "token", "country"]),

    clientItems() {
      return this.users.map((client) => {
        return {
          text: client.name,
          value: client.uid
        }
      })
    },

    users() {
      if (this.clients === null) return []
      return this.clients
    },

    skipValidation() {
      if (this.form.gauge_scans === false) return true
      return false
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    scrollToFirstInvalidField(element) {
      const children = element?.$children
      if (!children || !children?.length) return

      for (let child of children) {
        if (Object.keys(child).includes("valid") && !child.valid) {
          this.$nextTick(() => {
            const element = child.$el
            element.scrollIntoView({ behavior: "smooth", block: "center" })
          })
          return null
        }
        if (this.scrollToFirstInvalidField(child) === null) return null
      }
    },

    async createOrder() {
      const formIsValid = this.$refs.orderForm.validate()

      const scoreKey = this.form.immediate
        ? "scoreImmediate"
        : "scoreRestorative"

      if (
        formIsValid &&
        (this.validateScansResponse?.scanAnalysis[scoreKey] >= 4 ||
          this.skipValidation)
      ) {
        this.submitting = true
        this.loading = true
        const submitted_at = new Date().toISOString()
        await client.instance.orders
          .createOrder({
            ...this.form,
            nc_session_id: this.ncSessionId,
            session_type: this.form.immediate ? "Immediate" : "Definitive",
            submitted_at
          })
          .then(async (order) => {
            if (!this.skipValidation) {
              await this.uploadFiles(
                order.uid,
                order.sessions[0].uid,
                [this.leftToRightScan, this.rightToLeftScan],
                "scan",
                "scan_gauge_scan"
              )
            }
            await this.uploadFiles(
              order.uid,
              order.sessions[0].uid,
              this.scanFileUploads,
              "scan"
            )
            await this.uploadFiles(
              order.uid,
              order.sessions[0].uid,
              this.photoFileUploads,
              "photo"
            )
            await this.uploadFiles(
              order.uid,
              order.sessions[0].uid,
              this.otherFileUploads,
              "other"
            )
          })
          .catch(() => {
            this.submitting = false
            this.loading = false
            // Error handling?
            return console.error("Submission Failed")
          })
        this.submitting = false
        this.loading = false
        this.$router.push({ name: "inboxPage" })
        this.$emit("submission")
      } else {
        this.scrollToFirstInvalidField(this.$refs.orderForm)
      }
    },

    async uploadFiles(
      orderUid,
      orderSessionUid,
      files,
      fileType,
      fileCategory = null
    ) {
      if (!files.length) return

      const uploadedFiles = await client.oft.uploadFiles(files, (progress) => {
        if (fileCategory) this.scanGaugeUploadProgress = progress
        else {
          if (fileType === "scan") this.scanUploadProgress = progress
          if (fileType === "photo") this.photoUploadProgress = progress
          if (fileType === "other") this.otherUploadProgress = progress
        }
      })

      let payload = []

      if (uploadedFiles) {
        uploadedFiles.forEach((file) => {
          if (file.uploaded) {
            let payloadObj = {
              file_name: file.oftFileName,
              file_type: fileType,
              oft_file_uid: file.oftUid
            }
            if (fileCategory) payloadObj.file_category = fileCategory

            payload.push(payloadObj)
          } else this.failedUploads.push(file)
        })
      }

      await client.instance.orders.addFiles(orderUid, orderSessionUid, payload)
    },

    updateInstance(key, value) {
      this[key] = value
    },

    closeNewClientModal() {
      this.newClient = {}
      this.newClientDialog = false
    },

    async saveNewClient(clientForm, close = true) {
      this.loading = true
      const newClient = await client.instance.clients.createClient(clientForm)
      this.form.client_uid = newClient.uid
      this.triggerReload("clients")
      this.loading = false
      if (close) this.closeNewClientModal()
    }
  },

  async mounted() {
    this.initialising = true
    if (this.clients && this.settings) this.initialising = false
    else {
      this.triggerReload("clients")
      this.triggerReload("settings")
    }
  }
}
</script>
