<template>
  <v-card flat>
    <v-card-title class="title-font">
      CONNECTION TYPE
      <v-spacer />
      <div v-if="showErrors && !connectionType" class="error--text text-body-2">
        <v-icon color="error" class="mr-2">mdi-close-circle</v-icon>
        <span> Please select a Connection Type </span>
      </div>
    </v-card-title>
    <v-card-text>
      Choose which type of connection the file will be designed to.
    </v-card-text>
    <v-row class="mx-1 mb-2">
      <v-col cols="6">
        <v-menu offset-y rounded="10">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              outlined
              :class="[
                'd-flex flex-row rounded-10 pa-2 fill-height btn-hover',
                { 'btn-selected': directConnection }
              ]"
              v-ripple
              style="user-select: none; border-width: 2px"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                contain
                :src="require('@/assets/screw-comparison-rosen.png')"
              ></v-img>
              <v-card-text
                class="fill-height d-flex flex-column justify-center"
              >
                <p class="mb-0">
                  <strong>Direct to Multi-Unit Abutment</strong>
                </p>
                <p class="mb-0">
                  Screw Type:
                  <span
                    v-if="
                      !connectionType || connectionType === 'ti_base_to_mua'
                    "
                  >
                    (select from drop down)
                  </span>
                  <span v-if="screwSelected">
                    <strong> {{ screwSelected.title }} </strong>
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </template>
          <v-list dense>
            <v-subheader>SELECT SCREW TYPE:</v-subheader>
            <v-divider />

            <v-list-item
              v-for="(screw, index) in screwTypes"
              :key="index"
              @click="handleButtonSelect(screw.value)"
            >
              <v-list-item-content>
                <v-list-item-title> {{ screw.title }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          :class="[
            'd-flex flex-row rounded-10 pa-2 fill-height btn-hover ',
            { 'btn-selected': connectionType === 'ti_base_to_mua' }
          ]"
          v-ripple
          style="user-select: none; border-width: 2px"
          @click="handleButtonSelect('ti_base_to_mua')"
        >
          <v-img
            contain
            :src="require('@/assets/screw-comparison-Ti-base.png')"
          ></v-img>
          <v-card-text class="fill-height d-flex flex-column justify-center">
            <p class="mb-0">
              <strong>Ti-Base to Multi-Unit Abutment</strong>
            </p>
            <p class="mb-0">with prosthetic screw seat</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ConnectionTypeSelector",

  props: ["order", "value"],

  inject: ["form"],

  data() {
    return {
      showErrors: false,
      screwTypes: [
        {
          title: "Rosen Screw",
          value: "direct_rosen_screw"
        },
        {
          title: "Vortex Screw",
          value: "direct_vortex_screw"
        },
        {
          title: "Dess Flat Seat Screw",
          value: "direct_dess_flat_seat_screw"
        },
        {
          title: "Try-in",
          value: "direct_try_in"
        }
      ],
      valid: true
    }
  },

  computed: {
    connectionType: {
      get() {
        return this.value
      },
      set(value) {
        this.showErrors = false
        this.$emit("input", value)
      }
    },

    directConnection() {
      if (!this.connectionType) return false
      return this.connectionType.split("_")[0] === "direct"
    },

    screwSelected() {
      if (this.directConnection) {
        return this.screwTypes.find(
          (screw) => screw.value === this.connectionType
        )
      }
      return null
    }
  },

  methods: {
    handleButtonSelect(selection) {
      if (this.connectionType === selection) return (this.connectionType = null)
      this.connectionType = selection
    },

    validate() {
      if (!this.connectionType) this.showErrors = true
      this.valid = !!this.connectionType
      return !!this.connectionType
    },

    reset() {
      this.connectionType = null
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
