<template>
  <v-row class="my-0">
    <v-col xl="8" lg="8" md="12" sm="12" class="py-0">
      <v-card
        flat
        class="rounded-20 ma-0 mx-4 mt-4 d-flex flex-column"
        :height="outerCardHeight"
      >
        <v-card-title class="title-font">
          CHOOSE A PRODUCT TO ORDER
        </v-card-title>
        <v-card-text v-if="!immediateSelected">
          <v-row>
            <v-col
              xl="6"
              lg="12"
              md="12"
              sm="12"
              v-if="featureFlags.designs || featureFlags.bar"
            >
              <v-card flat outlined class="rounded-20" height="60vh">
                <v-card-title class="title-font">
                  <h6>UNFINISHED PRODUCTS</h6>
                </v-card-title>
                <v-row>
                  <v-col
                    v-if="featureFlags.designs"
                    align="center"
                    class="my-10"
                  >
                    <DesignButton
                      :disabled="
                        !selectedOrderSession.nc_session_id ||
                        !selectedOrderSession.associated
                      "
                      @chooseOrder="$emit('chooseOrder', 'DESIGN')"
                    />
                  </v-col>
                  <v-col v-if="featureFlags.bars" align="center" class="my-10">
                    <BarButton
                      :disabled="
                        !selectedOrderSession.nc_session_id ||
                        !selectedOrderSession.associated
                      "
                      @chooseOrder="$emit('chooseOrder', 'BAR')"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col
              xl="6"
              lg="12"
              md="12"
              sm="12"
              v-if="enableVSD || enableImmediate || featureFlags.finished_cases"
            >
              <v-card flat outlined class="rounded-20" height="60vh">
                <v-card-title class="title-font">
                  <h6>FINISHED PRODUCTS</h6>
                </v-card-title>
                <v-row>
                  <v-col v-if="enableVSD" align="center" class="my-10">
                    <VSDButton
                      @chooseOrder="
                        $emit('chooseOrder', 'VIRTUAL-SMILE-DESIGN')
                      "
                    />
                  </v-col>

                  <v-col v-if="enableImmediate" align="center" class="my-10">
                    <ImmediateButton
                      :disabled="
                        !selectedOrderSession.nc_session_id ||
                        !selectedOrderSession.associated
                      "
                      @chooseOrder="immediateSelected = true"
                    />
                  </v-col>

                  <v-col
                    v-if="featureFlags.finished_cases"
                    align="center"
                    class="my-10"
                  >
                    <FinishedProsthesisButton
                      :disabled="
                        !selectedOrderSession.nc_session_id ||
                        !selectedOrderSession.associated
                      "
                      @chooseOrder="$emit('chooseOrder', 'FINISHED-CASE')"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="immediateSelected">
          <v-card flat outlined class="rounded-20" height="60vh">
            <v-card-title class="title-font">
              <h6>IMMEDIATE PRODUCTS</h6>
            </v-card-title>
            <v-row v-if="immediateSelected">
              <v-col
                v-if="featureFlags.immediate_designs"
                align="center"
                class="my-10"
              >
                <ImmediateDesignButton
                  @chooseOrder="$emit('chooseOrder', 'IMMEDIATE-DESIGN')"
                />
              </v-col>

              <v-col
                v-if="featureFlags.immediate_bridges"
                align="center"
                class="my-10"
              >
                <ImmediateBridgeOnTiBaseButton
                  @chooseOrder="$emit('chooseOrder', 'IMMEDIATE-BRIDGE')"
                />
              </v-col>

              <v-col
                v-if="featureFlags.immediate_nexus_360s"
                align="center"
                class="my-10"
              >
                <ImmediateNexus360Button
                  @chooseOrder="$emit('chooseOrder', 'IMMEDIATE-NEXUS-360')"
                />
              </v-col>
            </v-row>
            <v-card-actions v-if="immediateSelected" class="ma-4">
              <v-spacer />
              <v-btn
                class="btn-primary rounded-10"
                @click="immediateSelected = false"
                text
              >
                Back
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      xl="4"
      lg="4"
      md="12"
      sm="12"
      v-if="orderForwardedOrders?.length"
      class="py-0"
    >
      <ExistingAndPartialOrders />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import ExistingAndPartialOrders from "./ChooseOrder/ExistingAndPartialOrders.vue"
import DesignButton from "./ChooseOrder/DesignButton.vue"
import BarButton from "./ChooseOrder/BarButton.vue"
import FinishedProsthesisButton from "./ChooseOrder/FinishedProsthesisButton.vue"
import ImmediateButton from "./ChooseOrder/ImmediateButton.vue"
import VSDButton from "./ChooseOrder/VSDButton.vue"
import ImmediateDesignButton from "./ChooseOrder/ImmediateDesignButton.vue"
import ImmediateBridgeOnTiBaseButton from "./ChooseOrder/ImmediateBridgeOnTiBaseButton.vue"
import ImmediateNexus360Button from "./ChooseOrder/ImmediateNexus360Button.vue"

export default {
  name: "ChooseOrder",

  components: {
    ExistingAndPartialOrders,
    DesignButton,
    BarButton,
    FinishedProsthesisButton,
    ImmediateButton,
    VSDButton,
    ImmediateDesignButton,
    ImmediateBridgeOnTiBaseButton,
    ImmediateNexus360Button
  },

  data() {
    return {
      partialOrders: [],
      immediateSelected: false
    }
  },

  computed: {
    ...mapGetters([
      "orderForwardedOrders",
      "selectedOrderSession",
      "featureFlags"
    ]),

    enableImmediate() {
      const virtualSmileDesigns = this.orderForwardedOrders.filter(
        (fo) =>
          fo.session_uid === this.selectedOrderSession.uid &&
          fo.product === "VIRTUAL-SMILE-DESIGN" &&
          fo.submitted_at
      )
      if (!virtualSmileDesigns.length) return false
      return virtualSmileDesigns.filter(
        (vsd) => vsd.case_details[0].status === "Dispatched"
      ).length
    },

    enableVSD() {
      if (!this.featureFlags.virtual_smile_designs) return false
      return !this.enableImmediate
    },

    outerCardHeight() {
      if (this.$vuetify.breakpoint.xl) return "72vh"
      return "144vh"
    }
  }
}
</script>
