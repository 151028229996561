<template>
  <v-form ref="form" lazy-validation>
    <v-card class="py-0" flat>
      <v-card-title class="title-font">
        UPLOAD PATIENT SCANS AND PHOTOS
      </v-card-title>
      <v-card-text class="pb-0">
        To accurately design a case, the correct scans and photos must be
        provided. Failure to provide adequate data may result in an undesirable
        design.
      </v-card-text>
      <v-row>
        <v-col cols="12" v-if="Object.keys(scans).length">
          <v-card-subtitle class="primary--text pb-2 px-2">
            <strong>Scans</strong>
          </v-card-subtitle>
          <v-card outlined class="mx-2">
            <div v-for="key in Object.keys(scans)" :key="key">
              <FileUploadAndPreview
                v-if="!foParams.fileCategories[key].multiple"
                v-model="files[key]"
                :categoryTitle="foParams.fileCategories[key].title"
                :categorySubtitle="foParams.fileCategories[key].subtitle"
                :category="foParams.fileCategories[key].value"
                :optional="foParams.fileCategories[key].optional"
                fileType="scan"
              />
              <MultipleFileUploadsAndPreview
                v-if="foParams.fileCategories[key].multiple"
                v-model="files[key]"
                :categoryTitle="foParams.fileCategories[key].title"
                :categorySubtitle="foParams.fileCategories[key].subtitle"
                :category="foParams.fileCategories[key].value"
                :optional="foParams.fileCategories[key].optional"
                fileType="scan"
              />
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="Object.keys(patientPhotos).length">
          <v-card-subtitle class="primary--text pb-2 px-2">
            <strong>Patient Photos</strong>
          </v-card-subtitle>
          <v-card outlined class="mx-2">
            <FileUploadAndPreview
              v-for="key in Object.keys(patientPhotos)"
              :key="key"
              v-model="files[key]"
              :categoryTitle="foParams.fileCategories[key].title"
              :categorySubtitle="foParams.fileCategories[key].subtitle"
              :category="foParams.fileCategories[key].value"
              :optional="foParams.fileCategories[key].optional"
              fileType="photo"
            />
          </v-card>
        </v-col>
        <v-col cols="12" v-if="Object.keys(otherFiles).length">
          <v-card-subtitle class="primary--text pb-2 px-2">
            <strong>Other Files</strong>
          </v-card-subtitle>
          <v-card outlined class="mx-2">
            <div v-for="key in Object.keys(otherFiles)" :key="key">
              <FileUploadAndPreview
                v-if="!foParams.fileCategories[key].multiple"
                v-model="files[key]"
                :categoryTitle="foParams.fileCategories[key].title"
                :category="foParams.fileCategories[key].value"
                :optional="foParams.fileCategories[key].optional"
                fileType="scan"
              />
              <MultipleFileUploadsAndPreview
                v-if="foParams.fileCategories[key].multiple"
                v-model="files[key]"
                :categoryTitle="foParams.fileCategories[key].title"
                :category="foParams.fileCategories[key].value"
                :optional="foParams.fileCategories[key].optional"
                fileType="scan"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex"
import { toCamelCase } from "@/utils"

import FileUploadAndPreview from "@/components/shared/FileUploadAndPreview.vue"
import MultipleFileUploadsAndPreview from "@/components/shared/MultipleFileUploadsAndPreview.vue"

export default {
  name: "UploadProjectFiles",

  components: {
    FileUploadAndPreview,
    MultipleFileUploadsAndPreview
  },

  props: ["value", "foParams"],

  inject: ["form"],

  data() {
    return {
      loading: false,
      files: {}
    }
  },

  watch: {
    files: {
      handler(files) {
        this.uploadedFiles = [...Object.values(files)].flat()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters(["orderFiles", "forwardedOrderForm"]),

    uploadedFiles: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    scans() {
      return Object.keys(this.files)
        .filter((k) => this.foParams.fileCategories[k].fileType === "scan")
        .reduce((arr, val) => {
          arr[val] = this.files[val]
          return arr
        }, {})
    },

    patientPhotos() {
      return Object.keys(this.files)
        .filter((k) => this.foParams.fileCategories[k].fileType === "photo")
        .reduce((arr, val) => {
          arr[val] = this.files[val]
          return arr
        }, {})
    },

    otherFiles() {
      return Object.keys(this.files)
        .filter((k) => this.foParams.fileCategories[k].fileType === "other")
        .reduce((arr, val) => {
          arr[val] = this.files[val]
          return arr
        }, {})
    }
  },

  methods: {
    handleUploaded(uploaded) {
      uploaded.forEach((upload) => {
        this.files[toCamelCase(upload.file_category)] = upload
      })
    },
    handleSelection(file, file_category) {
      const category = toCamelCase(file_category)
      this.files[category] = file
      // this.foParams.fileCategories[category].dialog = false
    },
    handleClear(category) {
      this.files[category] = null
    },

    validate() {
      return this.$refs.form.validate()
    },

    reset() {
      for (let key in this.files) this.files[key] = null
    }
  },

  created() {
    this.form.register(this)
  },

  mounted() {
    Object.entries(this.foParams.fileCategories).forEach(
      ([categoryName, category]) => {
        if (category.multiple) {
          this.$set(this.files, categoryName, [])
        } else {
          this.$set(this.files, categoryName, null)
        }
      }
    )

    const filteredFiles = this.orderFiles.filter((file) =>
      this.forwardedOrderForm.file_uids.includes(file.uid)
    )

    filteredFiles.forEach((file) => {
      if (
        this.foParams.fileCategories[toCamelCase(file.file_category)].multiple
      )
        this.files[toCamelCase(file.file_category)].push(file)
      else this.files[toCamelCase(file.file_category)] = file
    })
  }
}
</script>
