<template>
  <svg
    width="18"
    height="15"
    style="width: 100%; height: 100%"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.56866 1.07842C2.15175 1.07842 1.81377 1.41639 1.81377 1.83331V12.1861C1.81377 12.603 2.15175 12.941 2.56866 12.941H11.6274C12.0443 12.941 12.3823 12.603 12.3823 12.1861V7.44108H13.4607V12.1861C13.4607 13.1986 12.6399 14.0194 11.6274 14.0194H2.56866C1.55615 14.0194 0.735352 13.1986 0.735352 12.1861V1.83331C0.735352 0.820801 1.55615 0 2.56866 0H11.6274C12.6399 0 13.4607 0.820801 13.4607 1.83331V3.99014H12.3823V1.83331C12.3823 1.41639 12.0443 1.07842 11.6274 1.07842H2.56866Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7949 2.6659L17.5401 5.4111C17.7086 5.57956 17.7086 5.85268 17.5401 6.02114L14.7949 8.76635C14.6265 8.9348 14.3533 8.9348 14.1849 8.76635C14.0164 8.59789 14.0164 8.32476 14.1849 8.1563L16.1937 6.14749H10.3332C10.095 6.14749 9.90186 5.95436 9.90186 5.71612C9.90186 5.47788 10.095 5.28475 10.3332 5.28475H16.1937L14.1849 3.27594C14.0164 3.10748 14.0164 2.83435 14.1849 2.6659C14.3533 2.49744 14.6265 2.49744 14.7949 2.6659Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.77781 4.12887C2.6907 3.6643 3.0471 3.23486 3.51977 3.23486H6.36254C6.83521 3.23486 7.19161 3.6643 7.1045 4.12887L6.55381 7.06591L6.03578 10.1538C5.97477 10.5174 5.66001 10.7838 5.29129 10.7838H4.59102C4.22231 10.7838 3.90754 10.5174 3.84653 10.1538L3.3285 7.0659L2.77781 4.12887ZM3.51977 3.88191C3.45225 3.88191 3.40133 3.94326 3.41378 4.00963L3.96561 6.95274L4.48467 10.0467C4.49338 10.0987 4.53835 10.1367 4.59102 10.1367H5.29129C5.34396 10.1367 5.38893 10.0987 5.39764 10.0467L5.9167 6.95274L6.46854 4.00963C6.48098 3.94326 6.43007 3.88191 6.36254 3.88191H3.51977Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.37028 2.50636C3.45659 2.17342 3.75706 1.94092 4.10101 1.94092H5.78198C6.12593 1.94092 6.42639 2.17342 6.51271 2.50636L6.62455 2.93773C6.74848 3.41575 6.38764 3.88207 5.89381 3.88207H3.98917C3.49535 3.88207 3.13451 3.41575 3.25844 2.93773L3.37028 2.50636ZM4.10101 2.58797C4.05187 2.58797 4.00895 2.62118 3.99662 2.66875L3.88478 3.10011C3.86708 3.1684 3.91863 3.23502 3.98917 3.23502H5.89381C5.96436 3.23502 6.01591 3.1684 5.9982 3.10011L5.88637 2.66875C5.87404 2.62118 5.83111 2.58797 5.78198 2.58797H4.10101Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.51905 5.92046L3.93084 6.78319L3.79443 6.37396L6.38264 5.51123L6.51905 5.92046Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.51014 4.62913L3.49058 5.49186L3.37207 5.07709L6.39164 4.21436L6.51014 4.62913Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.09976 7.64124L3.94292 8.50397L3.78271 8.10346L5.93955 7.24072L6.09976 7.64124Z"
      :fill="color"
    />
    <path
      d="M9.98905 11.6555H9.45489L9.45826 11.2613H9.98905C10.1216 11.2613 10.2334 11.2315 10.3244 11.1719C10.4154 11.1113 10.4839 11.0231 10.5299 10.9074C10.5771 10.7917 10.6007 10.6518 10.6007 10.4878V10.3682C10.6007 10.2424 10.5872 10.1317 10.5603 10.0362C10.5344 9.94075 10.4957 9.86043 10.444 9.79528C10.3923 9.73012 10.3289 9.68126 10.2536 9.64868C10.1783 9.61498 10.0918 9.59813 9.9941 9.59813H9.44478V9.20215H9.9941C10.1581 9.20215 10.3081 9.23023 10.444 9.2864C10.5811 9.34144 10.6996 9.42064 10.7995 9.52399C10.8995 9.62734 10.9765 9.75091 11.0304 9.89469C11.0854 10.0374 11.113 10.1963 11.113 10.3716V10.4878C11.113 10.6619 11.0854 10.8209 11.0304 10.9647C10.9765 11.1085 10.8995 11.232 10.7995 11.3354C10.7007 11.4376 10.5822 11.5168 10.444 11.573C10.307 11.628 10.1553 11.6555 9.98905 11.6555ZM9.74135 9.20215V11.6555H9.23584V9.20215H9.74135Z"
      :fill="color"
    />
    <path
      d="M7.87291 10.218H8.1324C8.21553 10.218 8.28405 10.2039 8.33797 10.1758C8.39189 10.1466 8.43177 10.1062 8.45761 10.0545C8.48457 10.0017 8.49805 9.94049 8.49805 9.87085C8.49805 9.80794 8.48569 9.75233 8.46098 9.70403C8.43739 9.6546 8.40088 9.61641 8.35145 9.58945C8.30203 9.56136 8.23968 9.54732 8.16442 9.54732C8.10488 9.54732 8.04983 9.55912 7.99928 9.58271C7.94873 9.6063 7.90829 9.63943 7.87796 9.68212C7.84763 9.72481 7.83246 9.77648 7.83246 9.83714H7.34549C7.34549 9.70234 7.38144 9.58495 7.45333 9.48497C7.52635 9.385 7.62408 9.30692 7.74653 9.25076C7.86897 9.19459 8.00378 9.1665 8.15094 9.1665C8.31719 9.1665 8.46267 9.19346 8.58736 9.24739C8.71205 9.30018 8.80922 9.37826 8.87887 9.4816C8.94851 9.58495 8.98334 9.71301 8.98334 9.86579C8.98334 9.9433 8.96536 10.0186 8.92942 10.0916C8.89347 10.1635 8.8418 10.2286 8.7744 10.287C8.70812 10.3443 8.62724 10.3904 8.53175 10.4252C8.43627 10.4589 8.32899 10.4758 8.20991 10.4758H7.87291V10.218ZM7.87291 10.587V10.3359H8.20991C8.34359 10.3359 8.46098 10.3511 8.56208 10.3814C8.66318 10.4117 8.748 10.4555 8.81652 10.5128C8.88505 10.569 8.93672 10.6358 8.97154 10.7134C9.00637 10.7897 9.02378 10.8746 9.02378 10.9678C9.02378 11.0824 9.00187 11.1846 8.95806 11.2745C8.91425 11.3632 8.85247 11.4385 8.77271 11.5003C8.69408 11.5621 8.60196 11.6092 8.49637 11.6418C8.39077 11.6733 8.27563 11.689 8.15094 11.689C8.04759 11.689 7.94592 11.6749 7.84595 11.6469C7.74709 11.6177 7.65722 11.5744 7.57634 11.5171C7.49658 11.4587 7.43255 11.3857 7.38425 11.2981C7.33707 11.2093 7.31348 11.1043 7.31348 10.983H7.80045C7.80045 11.0459 7.81618 11.102 7.84763 11.1515C7.87908 11.2009 7.92233 11.2396 7.97738 11.2677C8.03354 11.2958 8.09589 11.3099 8.16442 11.3099C8.24193 11.3099 8.3082 11.2958 8.36325 11.2677C8.41942 11.2385 8.4621 11.1981 8.49131 11.1464C8.52164 11.0936 8.53681 11.0324 8.53681 10.9627C8.53681 10.8729 8.52052 10.801 8.48794 10.7471C8.45536 10.692 8.40874 10.6516 8.34808 10.6257C8.28742 10.5999 8.21553 10.587 8.1324 10.587H7.87291Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "ImplantExport",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
