<template>
  <v-container fluid>
    <div class="text-center" v-if="!hasTenants">
      <div class="my-5">
        <h1 class="title-h1">Pricing &amp; Inclusions</h1>
      </div>
      <p>
        Nexus Connect Lab Edition offers a full suite of features and services
        to enable you to provide end-to-end full-arch restorative services to
        your clients.
      </p>
    </div>
    <div class="text-center" v-if="hasTenants">
      <div class="my-5">
        <h1 class="title-h1">Reactivate Your Subscription</h1>
      </div>
      <div class="my-5">
        <h2 class="title-h2">{{ tenants[0].name }}</h2>
      </div>
      <p>
        Your subscription to Nexus Connect Lab Edition is no longer active.
        Please choose from a plan below to get started again:
      </p>
    </div>
    <v-row justify="center">
      <v-col
        xl="3"
        lg="3"
        md="6"
        sm="12"
        v-for="(pricing, index) in visiblePlans"
        :key="index"
      >
        <PricingCard :pricing="pricing" @selected="planSelected" />
      </v-col>
      <v-col xl="3" lg="3" md="6" sm="12">
        <v-card flat class="rounded-10">
          <v-card-title>
            <v-row class="text-center primary--text">
              <v-col>
                <h3>Have an Access Code?</h3>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <p>
              If you have been provided with an access code to subscribe, please
              enter it below and click Continue.
            </p>
            <v-form ref="accessCodeForm" @submit.prevent="processAccessCode">
              <v-text-field
                v-model="accessCode"
                :rules="rules"
                :error-messages="accessCodeError"
                placeholder="Access Code"
                outlined
                dense
              />
              <v-btn
                large
                class="btn-primary"
                min-width="100%"
                @click="processAccessCode"
              >
                Continue
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center my-10" v-if="plans.length">
      Need more? Contact Sales to discuss an
      <strong>Enterprise Plan</strong>
    </div>
    <v-row justify="center">
      <SignUp v-model="signUpActive" :selected-plan-code="selectedPlanCode" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { login } from "@/lib/authentication"
import client from "@/lib/ApiClient"
import PricingCard from "./PricingPage/PricingCard.vue"
import SignUp from "./PricingPage/SignUp.vue"

export default {
  name: "PricingPage",

  props: ["planCode"],

  components: {
    PricingCard,
    SignUp
  },

  data() {
    return {
      rules: [(v) => !!v || "Required"],
      signUpActive: false,
      selectedPlanCode: null,
      accessCode: null,
      accessCodeError: null
    }
  },

  computed: {
    ...mapGetters([
      "plans",
      "country",
      "loggedIn",
      "registered",
      "tenants",
      "hasTenant",
      "hasTenants",
      "activeSubscription"
    ]),

    visiblePlans() {
      return this.plans.filter((plan) => plan.visible)
    }
  },

  methods: {
    ...mapActions(["setPlans"]),

    async processAccessCode() {
      if (!this.$refs.accessCodeForm.validate()) return
      try {
        const plan = await client.gateway.plans.fetchPlanByAccessCode(
          this.country,
          this.accessCode
        )
        this.setPlans([...this.plans, { ...plan, _accessCode: true }])
        this.planSelected(plan.code)
        this.accessCode = null
        this.accessCodeError = null
      } catch {
        this.accessCodeError = "Invalid Access Code"
      }
    },

    planSelected(planCode) {
      if (this.registered) {
        if (this.hasTenants) {
          this.$router.push({
            name: "registrationPage",
            params: {
              planCode: planCode,
              tenantUID: this.tenants[0].uid
            }
          })
          return
        } else {
          this.$router.push({
            name: "registrationPage",
            params: {
              planCode: planCode
            }
          })
          return
        }
      }
      if (this.loggedIn) {
        this.selectedPlanCode = planCode
        this.signUpActive = !this.signUpActive
        return
      }
      login({
        name: "pricingPagePlanSelected",
        params: {
          planCode: planCode
        }
      })
    }
  },

  mounted() {
    if (this.hasTenant && this.activeSubscription) {
      this.$router.push({
        name: "inboxPage"
      })
      return
    }
    if (this.planCode) {
      this.planSelected(this.planCode)
    }
  }
}
</script>
