<template>
  <v-card
    flat
    class="ml-4 d-flex flex-column align-center justify-center rounded-20"
    height="100%"
  >
    <v-card-title>
      <div class="mx-auto">
        <div class="text-center mb-2">
          <v-progress-circular indeterminate size="64" v-if="loading" />
          <v-icon
            size="64"
            color="success"
            v-if="associationPass && !collisions.length && !loading"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            size="64"
            color="warning"
            v-if="associationPass && collisions.length && !loading"
          >
            mdi-check-circle
          </v-icon>
          <v-icon size="64" color="error" v-if="!associationPass && !loading">
            mdi-close-circle
          </v-icon>
        </div>
        <div>
          Scan Gauge Association
          <span v-if="associationPass && !loading"> Completed </span>
          <span v-if="!associationPass && !loading"> Failed </span>
        </div>
      </div>
    </v-card-title>
    <v-card-text v-if="collisions.length" class="text-center">
      <h3 class="black--text mb-4">Scan Gauges Too Close</h3>
      <p>
        When Scan Gauges are too close, the library may not be matched as one
        Scan Gauge may be causing another to seat incorrectly. This cannot be
        detected during the chairside analyzer step and could invalidate the
        scans. You may proceed to exporting implant positions, but please
        proceed with caution.
      </p>
      <h4 class="black--text">
        The following scan gauges are within 50 microns of eachother:
      </h4>
      <v-list-item v-for="(n, i) in collisions" :key="`collision-${i}`">
        <v-list-item-content>
          <v-list-item-title>
            Gauge
            <v-avatar size="32" color="warning">
              <span class="white--text">
                {{ n.gaugeIndices[0] + 1 }}
              </span>
            </v-avatar>
            and
            <v-avatar size="32" color="warning">
              <span class="white--text">
                {{ n.gaugeIndices[1] + 1 }}
              </span>
            </v-avatar>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-text class="text-center">
      If you want to redo the scan gauge library association, click on the redo
      association button below.
    </v-card-text>
    <v-card-actions>
      <v-btn
        small
        text
        class="btn-primary mx-auto"
        @click="$emit('redo')"
        :disabled="selectedOrderExports?.length > 0"
      >
        <v-icon class="mr-2">mdi-restore</v-icon>
        Redo Association
      </v-btn>
      <v-btn
        v-if="associationPass"
        small
        text
        class="btn-primary ml-3"
        :to="{ query: { tab: 'export' } }"
      >
        <v-icon class="mr-2">mdi-download</v-icon>
        Download Export
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "AssociationResult",

  props: ["associationPass", "collisions", "loading"],

  computed: {
    ...mapGetters(["selectedOrder", "selectedOrderExports"])
  }
}
</script>
