var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":_vm.selectedForwardedOrder?.submitted_at ||
      _vm.selectedForwardedOrder?.oep_submission_uid
        ? 6
        : 12}},[_c('v-card',{staticClass:"rounded-20 mx-4",attrs:{"flat":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('v-card-title',{staticClass:"title-font"},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v(_vm._s(_vm.determineIconFromProduct(_vm.currentFoParams.product)))]),_vm._v(" "+_vm._s(_vm.currentFoParams.title)+" "),(_vm.loading)?_c('v-progress-circular',{staticClass:"ml-5",attrs:{"indeterminate":""}}):_vm._e()],1),(
              !_vm.selectedForwardedOrder?.submitted_at &&
              !_vm.selectedForwardedOrder?.oep_submission_uid
            )?_c('v-card-text',[_vm._v(" Continue your order by completing the necessary options. ")]):_vm._e()],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[(_vm.currentFoParams.image)?_c('v-card-text',{staticClass:"fill-width d-flex justify-center pb-0"},[_c('v-img',{staticClass:"mx-4",attrs:{"src":_vm.currentFoParams.image,"contain":"","max-width":"200","max-height":"100"}})],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"pt-0"},[(
            _vm.currentFoParams.caseType === 'handover' &&
            _vm.selectedForwardedOrder?.oep_submission_uid
          )?_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" Continue your order on the Nexus IOS ordering platform. Once submitted, the order details will be displayed below. "),_c('v-card-text',[_c('v-card',{staticClass:"rounded-10 pa-2 mb-3",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.determineIconFromProduct(_vm.selectedForwardedOrder.product))+" ")]),_vm._v(" "+_vm._s(_vm.toTitleCase(_vm.selectedForwardedOrder.product))+" "),_c('v-chip',{staticClass:"px-1 ml-2",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.sessionName(_vm.selectedForwardedOrder.session_uid)))])],1),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm._f("luxon")(_vm.selectedForwardedOrder.created_at,{ output: "short" }))+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"btn-primary mr-2 pa-2",on:{"click":_vm.openNexusOrderingCase}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.selectedForwardedOrder?.submitted_at)?_c('OrderSummary'):_vm._e()],1),(
          !_vm.selectedForwardedOrder?.submitted_at &&
          !_vm.selectedForwardedOrder?.oep_submission_uid
        )?_c('div',[_c('ForwardedOrderOptions',{attrs:{"foParams":_vm.currentFoParams},on:{"back":function($event){return _vm.$emit('back')},"next":function($event){return _vm.handleStepChange(++_vm.workflowStep)}}})],1):_vm._e()],1)],1),(
      _vm.selectedForwardedOrder?.submitted_at ||
      _vm.selectedForwardedOrder?.oep_submission_uid
    )?_c('v-col',[_c('CaseChat',{staticClass:"mr-4"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }