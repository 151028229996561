<template>
  <v-row>
    <v-col cols="12" class="ma-0">
      <v-select
        v-if="!isArchived"
        v-model="selectedStatuses"
        multiple
        :items="filteredStatuses"
        outlined
        hide-details
        :menu-props="{ rounded: '10' }"
      >
        <template v-slot:item="{ item }">
          <v-icon small class="mr-2">{{ item.icon }}</v-icon>
          {{ item.text }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip
            :outlined="!['waiting'].includes(item.value)"
            :color="item.value === 'message' ? 'success' : null"
            small
            :class="[
              'mr-1',
              { 'notification-warning': item.value === 'waiting' }
            ]"
          >
            <v-icon small class="mr-2">{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-chip>
        </template>
      </v-select>
      <v-row v-if="isArchived">
        <v-col>
          <v-chip small outlined class="mr-1">
            <v-icon small class="mr-2">$inboxarchive</v-icon>
            ARCHIVED
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn
        small
        class="btn-primary"
        v-if="!isArchived && !confirmArchive"
        @click="confirmArchive = true"
      >
        <v-icon class="mr-2">$inboxarchiveDark</v-icon>
        Archive this Order
      </v-btn>
      <v-card flat elevation="0" v-if="confirmArchive">
        <v-card-text>
          <strong>Are you sure you want to archive this order? </strong>
          <p>This order will be removed from all other statuses.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small class="btn-success" @click="archive">
            <v-icon class="mr-2">mdi-check</v-icon>
            Confirm Archive
          </v-btn>
          <v-btn small class="btn-error" @click="confirmArchive = false">
            <v-icon class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-btn
        small
        class="btn-primary"
        v-if="isArchived && !confirmUnArchive"
        @click="confirmUnArchive = true"
      >
        <v-icon class="mr-2">$inboxDark</v-icon>
        Un-Archive This Order
      </v-btn>
      <v-card flat elevation="0" v-if="confirmUnArchive">
        <v-card-text>
          <strong>Are you sure you want to un-archive this order? </strong>
          <p>This order will be placed in your order inbox.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small class="btn-success" @click="unArchive">
            <v-icon class="mr-2">mdi-check</v-icon>
            Confirm Un-Archive
          </v-btn>
          <v-btn small class="btn-error" @click="confirmUnArchive = false">
            <v-icon class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import client from "@/lib/ApiClient"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "StatusComponent",

  data() {
    return {
      confirmArchive: false,
      confirmUnArchive: false,
      statuses: [
        { text: "INBOX", icon: "$inboxreceived", value: "inbox" },
        {
          text: "IMPLANTS EXPORTED",
          icon: "$implantexport",
          value: "exported"
        },
        { text: "AWAITING RESPONSE", icon: "$alarm", value: "waiting" },
        { text: "DESIGN ONLY", icon: "$toothdesigns", value: "design" },
        { text: "MILLED BAR", icon: "$milledbar", value: "bar" },
        {
          text: "FINISHED CASE",
          icon: "$finishedprosthetic",
          value: "finished_case"
        },
        {
          text: "VIRTUAL SMILE DESIGN",
          icon: "$virtualSmileDesign",
          value: "virtual_smile_design"
        },
        {
          text: "IMMEDIATE DESIGN",
          icon: "$immediateDesign",
          value: "immediate_design"
        },
        {
          text: "IMMEDIATE BRIDGE",
          icon: "$surgicalTool",
          value: "immediate_bridge"
        },
        {
          text: "IMMEDIATE NEXUS 360°",
          icon: "$surgicalTool",
          value: "immediate_nexus_360"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "featureFlags"]),

    selectedStatuses: {
      get() {
        return this.selectedOrder.statuses
      },
      async set(value) {
        if (value.length === 0) return
        await client.instance.orders.changeOrderStatuses(
          this.selectedOrder.uid,
          value
        )
        this.triggerReload("order")
        this.triggerReload("inboxItems")
      }
    },

    isArchived() {
      return this.selectedStatuses.includes("archived")
    },

    filteredStatuses() {
      let filteredStatuses = this.statuses
      if (!this.featureFlags.designs)
        filteredStatuses = filteredStatuses.filter((s) => s.value !== "design")
      if (!this.featureFlags.bars)
        filteredStatuses = filteredStatuses.filter((s) => s.value !== "bar")
      if (!this.featureFlags.finished_cases)
        filteredStatuses = filteredStatuses.filter(
          (s) => s.value !== "finished_case"
        )
      if (!this.featureFlags.virtual_smile_designs)
        filteredStatuses = filteredStatuses.filter(
          (s) => s.value !== "virtual_smile_design"
        )
      if (!this.featureFlags.immediate_designs)
        filteredStatuses = filteredStatuses.filter(
          (s) => s.value !== "immediate_design"
        )
      if (!this.featureFlags.immediate_bridges)
        filteredStatuses = filteredStatuses.filter(
          (s) => s.value !== "immediate_bridge"
        )
      if (!this.featureFlags.immediate_nexus_360s)
        filteredStatuses = filteredStatuses.filter(
          (s) => s.value !== "immediate_nexus_360"
        )

      return filteredStatuses
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    async archive() {
      await client.instance.orders.changeOrderStatuses(this.selectedOrder.uid, [
        "archived"
      ])
      this.triggerReload("order")
      this.triggerReload("inboxItems")
      this.confirmArchive = false
    },

    async unArchive() {
      await client.instance.orders.changeOrderStatuses(this.selectedOrder.uid, [
        "inbox"
      ])
      this.triggerReload("order")
      this.triggerReload("inboxItems")
      this.confirmUnArchive = false
    }
  }
}
</script>
