<template>
  <div>
    <v-card flat class="transparent">
      <v-card flat class="ma-4 rounded-20">
        <v-card-title class="title-font">
          SCAN GAUGE SCAN LIBRARY ASSOCIATION
        </v-card-title>
        <v-card-subtitle>
          Carefully select each Scan Gauge used in the kit with the
          corresponding gauge in the scan. Use the round-dimples to check that
          they match.
        </v-card-subtitle>
      </v-card>

      <v-row>
        <template v-if="!selectedOrderSession.associated && !showFailure">
          <v-col cols="5">
            <GaugeSelection
              :gauges="gauges"
              :libraryAssociation="selectedGaugeLibraries"
              :active="selectedScanGaugeIndex >= 0"
              :loading="loading"
              v-model="selectedKitGauge"
              @start="scanGaugeSelect(0)"
              @delete="deleteAssociatedGauge"
            />
          </v-col>
          <v-col cols="2">
            <SelectedGauge
              :selectedKitGauge="selectedKitGauge"
              :selectedScanGaugeIndex="selectedScanGaugeIndex"
              @confirm="confirmSelection"
            />
          </v-col>
        </template>
        <v-col cols="7" v-if="selectedOrderSession.associated || showFailure">
          <AssociationResult
            :associationPass="associationPass"
            :collisions="libraryAssociationCollisions || []"
            :loading="loading"
            @redo="redoAssociation"
          />
        </v-col>

        <v-col cols="5">
          <v-card flat class="mr-4 rounded-20 d-flex flex-column" height="100%">
            <v-card-title class="title-font">
              SCAN GAUGE ALIGNMENT
            </v-card-title>
            <v-card-text style="width: 100%; height: 415px" class="text-center">
              <v-row
                v-if="loading"
                justify="center"
                align="center"
                class="fill-height"
              >
                <LoadingSpinner />
              </v-row>
              <div v-show="!loading" ref="view"></div>
            </v-card-text>

            <ScanSelectConfirm
              v-if="
                !selectedOrderSession.associated &&
                !showFailure &&
                selectedGaugeLibraries.length
              "
              :selectedGaugeLibraries="selectedGaugeLibraries"
              :selectedScanGaugeIndex="selectedScanGaugeIndex"
              :loading="loading"
              @select="scanGaugeSelect"
              @confirm="dialog = true"
            />
            <v-card flat v-if="selectedOrderSession.associated || showFailure">
              <v-card-text>
                <v-row>
                  <v-col class="ml-10">
                    <div></div>
                    <v-switch
                      :disabled="loading"
                      inset
                      dense
                      hide-details
                      label="Scan Gauge Libraries"
                      v-model="showLibraries"
                      class="mt-2"
                    />
                    <v-switch
                      :disabled="loading"
                      inset
                      dense
                      hide-details
                      label="Constructed Libraries"
                      v-model="showConstructedLibraries"
                      class="mt-2"
                    />
                  </v-col>
                  <v-col
                    :class="{
                      'mr-10': true,
                      'ml-10': $vuetify.breakpoint.lgAndDown
                    }"
                  >
                    <v-switch
                      :disabled="loading"
                      inset
                      dense
                      hide-details
                      label="Scan Gauges"
                      v-model="showScanGauges"
                      class="mt-2"
                    />
                    <v-switch
                      :disabled="loading"
                      inset
                      dense
                      hide-details
                      label="Tissue Scan"
                      v-model="showScanTissue"
                      class="mt-2"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      max-width="500px"
      persistent
      v-model="dialog"
      content-class="rounded-20"
    >
      <v-card>
        <v-card-title class="off mb-2">
          <h2 class="title-h2 primary--text">CONFIRM LIBRARY ASSOCIATION</h2>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>
            Do you confirm each Scan Gauge from the scan is correctly associated
            with its corresponding library from the Scan Gauge Kit?
          </p>
          <p>
            Incorrect library associations will be detrimental to any further
            processes for this case.
          </p>
          <p v-if="loading">
            <strong>Please be patient, this may take a few minutes...</strong>
          </p>
        </v-card-text>
        <v-card-actions class="off">
          <v-spacer />
          <v-btn class="btn-error" :disabled="loading" @click="dialog = false">
            Back
          </v-btn>
          <v-btn
            class="btn-primary"
            @click="confirmAssociation"
            :loading="loading"
            :disabled="loading"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import apiClient from "@/lib/ApiClient"
import GaugeSelection from "./LibraryAssociation/GaugeSelection.vue"
import SelectedGauge from "./LibraryAssociation/SelectedGauge.vue"
import AssociationResult from "./LibraryAssociation/AssociationResult.vue"
import ScanSelectConfirm from "./LibraryAssociation/ScanSelectConfirm.vue"

export default {
  name: "LibraryAssociation",

  components: {
    GaugeSelection,
    SelectedGauge,
    AssociationResult,
    ScanSelectConfirm
  },

  view: null,

  data() {
    return {
      selectedKitGauge: null,
      selectedScanGaugeIndex: -1,
      dialog: false,
      loading: false,
      selectedGaugeLibraries: [],

      showFailure: false,

      enableLibraryAssociation: false,
      libraryAssociationCollisions: null,
      libraryAssociationMetrics: null,
      showScanGauges: true,
      showScanTissue: true,
      showLibraries: true,
      showConstructedLibraries: true,
      // showAnalogs: true,

      // mounted() must attach ['scanBodyCode'] to each gauge object
      gauges: [
        [
          {
            kitGaugeId: 1,
            src: require("@/assets/gauges/0000_3-mm-short-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 2,
            src: require("@/assets/gauges/0001_3-mm-short-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 3,
            src: require("@/assets/gauges/0002_3-mm-short-3.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 4,
            src: require("@/assets/gauges/0003_3-mm-medium-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 5,
            src: require("@/assets/gauges/0004_3-mm-medium-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 6,
            src: require("@/assets/gauges/0005_3-mm-medium-3.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 7,
            src: require("@/assets/gauges/0006_3-mm-long-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 8,
            src: require("@/assets/gauges/0007_3-mm-long-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 9,
            src: require("@/assets/gauges/0008_3-mm-long-3.png"),
            hemisphereMarkings: 2
          }
        ],
        [
          {
            kitGaugeId: 10,
            src: require("@/assets/gauges/0009_5-mm-short-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 11,
            src: require("@/assets/gauges/0010_5-mm-short-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 12,
            src: require("@/assets/gauges/0011_5-mm-short-3.png"),
            hemisphereMarkings: 3
          },
          {
            kitGaugeId: 13,
            src: require("@/assets/gauges/0012_5-mm-medium-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 14,
            src: require("@/assets/gauges/0013_5-mm-medium-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 15,
            src: require("@/assets/gauges/0014_5-mm-medium-3.png"),
            hemisphereMarkings: 3
          },
          {
            kitGaugeId: 16,
            src: require("@/assets/gauges/0015_5-mm-long-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 17,
            src: require("@/assets/gauges/0016_5-mm-long-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 18,
            src: require("@/assets/gauges/0017_5-mm-long-3.png"),
            hemisphereMarkings: 3
          }
        ]
      ]
    }
  },

  mounted() {
    this.getNcSessionAndLibraries()
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedOrderSession",
      "token",
      "cachedNcSession",
      "cachedNcScanBodies"
    ]),

    associationPass() {
      const metricsPass = this.libraryAssociationMetrics?.every((m) => m?.pass)
      // const collisions = this.libraryAssociationCollisions?.length
      return metricsPass
      // && !collisions
    }
  },

  watch: {
    enableLibraryAssociation(value) {
      this.view.visualisation.enableLibraryAssociation(value)
    },
    showScanGauges(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setScanGaugesVisible(value)
      }
    },
    showScanTissue(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setScanTissueVisible(value)
      }
    },
    showLibraries(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setLibrariesVisible(value)
      }
    },
    showConstructedLibraries(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setConstructedLibrariesVisible(value)
      }
    }
    // showAnalogs(value) {
    //   if (this.view && this.view.visualisation) {
    //     this.view.visualisation.setAnalogsVisible(value)
    //   }
    // }
  },

  methods: {
    ...mapActions([
      "setSelectedOrder",
      "setCachedNcSession",
      "setCachedNcScanBodies"
    ]),

    confirmSelection() {
      this.$set(
        this.selectedGaugeLibraries,
        this.selectedScanGaugeIndex,
        this.selectedKitGauge.scanBodyCode
      )

      this.selectedKitGauge = null

      if (this.selectedGaugeLibraries.every((g) => !!g)) {
        this.selectedScanGaugeIndex = -1
        this.view.visualisation.enableLibraryAssociation(true)
      } else {
        this.selectedScanGaugeIndex = this.selectedGaugeLibraries.findIndex(
          (g) => g === null
        )
        this.view.visualisation.zoomToGauge(this.selectedScanGaugeIndex)
      }

      if (this.selectedGaugeLibraries[this.selectedScanGaugeIndex]) {
        const gauge = this.gauges
          .flat()
          .find(
            (g) =>
              g.scanBodyCode ===
              this.selectedGaugeLibraries[this.selectedScanGaugeIndex]
          )
        this.selectedKitGauge = gauge
      }
    },

    async confirmAssociation() {
      try {
        this.loading = true
        const response = await NCFrontend.ApiClient.sessionAssociateLibraries(
          `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
          this.$axios.defaults.headers.Authorization,
          {
            sessionId: this.selectedOrderSession.nc_session_id,
            kitBoxId: this.selectedOrderSession.kit_box_id,
            selectedLibraries: this.selectedGaugeLibraries
          }
        )
        this.enableLibraryAssociation = false
        this.libraryAssociationCollisions =
          response.librariesCombinedScanComparison.collisions
        this.libraryAssociationMetrics =
          response.librariesCombinedScanComparison.libraryComparisons.map(
            (obj) => {
              return { value: obj.percentiles[2], pass: obj.passed }
            }
          )
        this.view.visualisation.setLibraryAssociationData(response)
        this.view.visualisation.setLibrariesVisible(false)

        this.showScanGauges = false
        this.showScanTissue = true
        this.showLibraries = false
        this.showConstructedLibraries = true
        // this.showAnalogs = true

        // TODO: This won't work!!
        if (this.libraryAssociationMetrics?.every((m) => m?.pass)) {
          await apiClient.instance.orders.associateOrder(
            this.selectedOrder.uid,
            this.selectedOrderSession.uid
          )
          this.setSelectedOrder(
            await apiClient.instance.orders.getOrder(this.selectedOrder.uid)
          )

          const sessionResponse = await NCFrontend.ApiClient.sessionGet(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            this.$axios.defaults.headers.Authorization,
            this.selectedOrderSession.nc_session_id
          )
          this.setCachedNcSession({
            id: this.selectedOrderSession.nc_session_id,
            response: sessionResponse
          })
        } else this.showFailure = true
      } catch (err) {
        console.error("An internal error occurred.")
        throw err
      } finally {
        this.loading = false
        this.dialog = false
      }
    },

    scanGaugeSelect(index) {
      if (
        this.selectedGaugeLibraries[this.selectedScanGaugeIndex] &&
        !this.selectedGaugeLibraries[index]
      )
        this.selectedKitGauge = null

      if (this.selectedScanGaugeIndex === index) {
        this.selectedKitGauge = null
        this.selectedScanGaugeIndex = -1
        return this.view.visualisation.enableLibraryAssociation(true)
      }

      if (this.selectedGaugeLibraries[index]) {
        const gauge = this.gauges
          .flat()
          .find((g) => g.scanBodyCode === this.selectedGaugeLibraries[index])
        this.selectedKitGauge = gauge
      }

      this.selectedScanGaugeIndex = index
      this.view.visualisation.zoomToGauge(index)
    },

    deleteAssociatedGauge(gaugeId) {
      const idx = this.selectedGaugeLibraries.indexOf(gaugeId)
      this.$set(this.selectedGaugeLibraries, idx, null)
    },

    async redoAssociation() {
      this.loading = true

      // TODO: This won't work!!
      if (!this.showFailure) {
        await apiClient.instance.orders.redoAnalysis(
          this.selectedOrder.uid,
          this.selectedOrderSession.uid
        )
        this.setSelectedOrder(
          await apiClient.instance.orders.getOrder(this.selectedOrder.uid)
        )
      } else this.showFailure = false
      this.selectedGaugeLibraries.fill(null)
      this.showScanGauges = true
      this.showScanTissue = true
      this.showLibraries = false
      this.showConstructedLibraries = false
      // this.showAnalogs = false
      this.enableLibraryAssociation = true
      this.loading = false
    },

    async getNcSessionAndLibraries() {
      this.loading = true

      let sessionResponse, gaugeKitResponse

      const promises = []

      if (
        this.selectedOrderSession.nc_session_id === this.cachedNcSession?.id
      ) {
        sessionResponse = this.cachedNcSession.response
      } else
        promises.push(
          NCFrontend.ApiClient.sessionGet(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            this.$axios.defaults.headers.Authorization,
            this.selectedOrderSession.nc_session_id
          )
        )

      if (
        this.selectedOrderSession.kit_box_id ===
        this.cachedNcScanBodies?.kit_box_id
      ) {
        gaugeKitResponse = this.cachedNcScanBodies.response
      } else
        promises.push(
          NCFrontend.ApiClient.gaugeKitGetScanBodyCodes(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            this.$axios.defaults.headers.Authorization,
            this.selectedOrderSession.kit_box_id
          )
        )

      const responses = await Promise.all(promises)

      if (!gaugeKitResponse) {
        gaugeKitResponse = responses[sessionResponse ? 0 : 1]
        this.setCachedNcScanBodies({
          kit_box_id: this.selectedOrderSession.kit_box_id,
          response: gaugeKitResponse
        })
      }

      if (!sessionResponse) {
        sessionResponse = responses[0]
        this.setCachedNcSession({
          id: this.selectedOrderSession.nc_session_id,
          response: sessionResponse
        })
      }

      if (!this.$refs.view) return

      this.view = NCFrontend.createView(
        "libraryAssociationView",
        this.$refs.view
      )

      this.selectedGaugeLibraries = new Array(
        sessionResponse.fixedScan.scanGauges.length
      ).fill(null)

      NCFrontend.add(this.view)

      this.view.setVisualisation(
        new NCFrontend.Visualisations.LibraryAssociation(this.view.element, {
          fixedScan: sessionResponse.fixedScan,
          scanAnalysis: sessionResponse.scanAnalysis,
          immediateCase: this.selectedOrderSession.session_type === "immediate"
        })
      )

      this.gauges = this.gauges.map((cuffHeightSet, setIndex) => {
        const cuffHeight = setIndex === 0 ? "3mm" : "5mm"
        return cuffHeightSet.map((gauge, index) => {
          const offset = cuffHeight === "5mm" ? 9 : 0
          return {
            ...gauge,
            scanBodyCode: gaugeKitResponse.scanBodyCodes[index + offset]
          }
        })
      })

      if (!this.selectedOrderSession.associated)
        setTimeout(() => {
          this.enableLibraryAssociation = true
          this.loading = false
        }, 0)
      else {
        setTimeout(() => {
          this.view.visualisation.setLibraryAssociationData(
            sessionResponse.libraryAssociation
          )
          this.libraryAssociationCollisions =
            sessionResponse.libraryAssociation.librariesCombinedScanComparison.collisions
          this.libraryAssociationMetrics =
            sessionResponse.libraryAssociation.librariesCombinedScanComparison.libraryComparisons.map(
              (obj) => {
                return { value: obj.percentiles[2], pass: obj.passed }
              }
            )
          this.showScanGauges = false
          this.showScanTissue = true
          this.showLibraries = false
          this.showConstructedLibraries = true
          this.loading = false
          // this.showAnalogs = true
        }, 0)
      }
    }
  }
}
</script>
