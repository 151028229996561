import { axiosClient } from "@/plugins/axios"

export class Users {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetch_me() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/users/me`
    )
    return response.data
  }
}

export class Orders {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  /* Products */

  async getQuestions() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/form/`
    )
    return response.data
  }

  async createForm(payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/form/`,
      payload
    )
    return response.data
  }

  /* Questions */

  async deleteQuestion(uid) {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/orders/question/${uid}`
    )
    return response.data
  }

  /* Orders */

  async getOrders() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/`
    )
    return response.data
  }

  async createOrder(form) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/`,
      form
    )
    return response.data
  }

  async getOrder(orderUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/`
    )
    return response.data
  }

  async assignOrder(sourceOrderUid, targetOrderUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${sourceOrderUid}/assign/${targetOrderUid}/`
    )
    return response.data
  }

  async viewOrder(orderUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/viewed/`
    )
    return response.data
  }

  async associateOrder(orderUid, sessionUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${sessionUid}/associated/`
    )
    return response.data
  }

  async associateSession(orderUid, sessionUid, payload) {
    const response = await axiosClient.patch(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${sessionUid}/`,
      payload
    )
    return response.data
  }

  async redoAnalysis(orderUid, sessionUid) {
    const response = await axiosClient.put(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${sessionUid}/associated/`
    )
    return response.data
  }

  async changeOrderStatuses(orderUid, payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/status/`,
      payload
    )
    return response.data
  }

  async createNewOrderSession(orderUid, payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/sessions/`,
      payload
    )
    return response.data
  }

  /* Files */
  async addFiles(orderUid, orderSessionUid, payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/files/`,
      payload
    )
    return response.data
  }

  async fetchOrderFiles(orderUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/files/`
    )
    return response.data
  }

  async updateFileCategory(orderUid, orderSessionUid, fileUid, category) {
    const response = await axiosClient.put(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/files/${fileUid}/`,
      {
        category
      }
    )
    return response.data
  }

  async deleteOrderFile(orderUid, orderSessionUid, fileUid) {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/files/${fileUid}/`
    )
    return response.data
  }

  /* Forwarded Orders */

  async getAllForwardedOrders() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/forwarded_orders/all/`
    )
    return response.data
  }

  async getForwardedOrders(orderUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/forwarded_orders/`
    )
    return response.data
  }

  async deleteForwardedOrder(orderUid, orderSessionUid, forwardedOrderUid) {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/forwarded_orders/${forwardedOrderUid}/`
    )
    return response.data
  }

  async getForwardedOrder(orderUid, orderSessionUid, forwardedOrderUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/forwarded_orders/${forwardedOrderUid}/`
    )
    return response.data
  }

  async createForwardedOrder(orderUid, orderSessionUid, form) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/forwarded_orders/`,
      form
    )
    return response.data
  }

  async updateForwardedOrder(
    orderUid,
    orderSessionUid,
    forwardedOrderUid,
    form
  ) {
    const response = await axiosClient.put(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/session/${orderSessionUid}/forwarded_orders/${forwardedOrderUid}/`,
      form
    )
    return response.data
  }

  async submitForwardedOrder(orderUid, forwardedOrderUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/forwarded_order/${forwardedOrderUid}/submit/`
    )
    return response.data
  }

  async uploadForwardedOrderFiles(orderUid, forwardedOrderUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/orders/order/${orderUid}/forwarded_orders/${forwardedOrderUid}/files/`
    )
    return response.data
  }
}

export class Clients {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchClients() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/clients/`
    )
    return response.data
  }

  async createClient(form) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/clients/`,
      form
    )
    return response.data
  }

  async updateClient(clientUid, form) {
    const response = await axiosClient.put(
      `${this.instanceUrl}/${this.tenantUid}/clients/${clientUid}/`,
      form
    )
    return response.data
  }

  async deleteClient(uid) {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/clients/${uid}/`
    )
    return response.data
  }

  async addIdentity(uid, payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/clients/${uid}/id_string/`,
      payload
    )
    return response.data
  }

  async removeIdentity(client_uid, id_string_uid) {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/clients/${client_uid}/id_string/${id_string_uid}/`
    )
    return response.data
  }
}

export class Tenants {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchSettings() {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/settings/`
    )
    return response.data
  }

  async uploadLogo(payload) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/settings/logo/`,
      payload
    )
    return response.data
  }

  async deleteLogo() {
    const response = await axiosClient.delete(
      `${this.instanceUrl}/${this.tenantUid}/settings/logo/`
    )
    return response.data
  }

  async updateSettings(settings) {
    const response = await axiosClient.put(
      `${this.instanceUrl}/${this.tenantUid}/settings/`,
      settings
    )
    return response.data
  }
}

export class Messages {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchMessages(orderUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/messages/${orderUid}/`
    )
    return response.data
  }

  async sendMessage(orderUid, message) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/messages/${orderUid}/`,
      message
    )
    return response
  }

  async viewMessages(orderUid) {
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/messages/${orderUid}/read/`
    )
    return response
  }

  async fetchApprovals(orderUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/messages/${orderUid}/approvals/`
    )
    return response.data
  }

  async approvalAction(approvalUid, action) {
    const changes = ["request_changes", "approve_with_changes"].includes(action)
    const response = await axiosClient.post(
      `${this.instanceUrl}/${this.tenantUid}/messages/approval/${approvalUid}/action/`,
      { action, changes }
    )
    return response.data
  }
}

export class Exports {
  constructor() {
    this.tenantUid = null
    this.instanceUrl = null
  }

  setInstanceUrl(url) {
    this.instanceUrl = url
  }

  setTenantUid(uid) {
    this.tenantUid = uid
  }

  async fetchHistoryExports(orderSessionUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/exports/${orderSessionUid}/`
    )
    return response.data
  }

  async checkExport(orderSessionUid, exportUid) {
    try {
      const response = await axiosClient.get(
        `${this.instanceUrl}/${this.tenantUid}/exports/${orderSessionUid}/check/${exportUid}/`
      )
      return response.status === 200
    } catch {
      return false
    }
  }

  async exportImplantPositions(orderSessionUid) {
    const response = await axiosClient.get(
      `${this.instanceUrl}/${this.tenantUid}/validation/${orderSessionUid}/export_implant_positions/`
    )
    return response.data
  }
}

export default class InstanceAPI {
  constructor() {
    this.users = new Users()
    this.orders = new Orders()
    this.clients = new Clients()
    this.tenants = new Tenants()
    this.messages = new Messages()
    this.exports = new Exports()
  }

  setInstanceUrl(url) {
    this.users.setInstanceUrl(url)
    this.orders.setInstanceUrl(url)
    this.clients.setInstanceUrl(url)
    this.tenants.setInstanceUrl(url)
    this.messages.setInstanceUrl(url)
    this.exports.setInstanceUrl(url)
  }

  setTenantUid(uid) {
    this.users.setTenantUid(uid)
    this.orders.setTenantUid(uid)
    this.clients.setTenantUid(uid)
    this.tenants.setTenantUid(uid)
    this.messages.setTenantUid(uid)
    this.exports.setTenantUid(uid)
  }
}
