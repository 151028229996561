var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
        'rounded-10',
        'blue-border',
        { 'btn-primary': hover, 'disabled-button': _vm.disabled }
      ],attrs:{"height":"175","width":"175"},on:{"click":function($event){return _vm.$emit('chooseOrder', 'FINISHED')}}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1 pb-0",style:({
          color: hover ? 'white' : '#147BD1'
        })},[_c('strong',[_vm._v(" FINISHED PROSTHESIS ")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/final-prosthesis-bar-retained.png'),"position":"center center"}})],1)],1),(hover)?_c('v-card',{attrs:{"flat":"","width":"200px"}},[_c('v-card-text',{staticClass:"text-left pa-2"},[_c('ul',[_c('li',[_vm._v("Completely finished Nexus Hybrid prosthesis.")]),_c('li',[_vm._v("Try-In included in process.")]),_c('li',[_vm._v("Ready for patient insert.")])])])],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }