var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:[
        'rounded-10',
        'blue-border',
        { 'btn-primary': hover, 'disabled-button': _vm.disabled }
      ],attrs:{"flat":"","height":"175","width":"175"},on:{"click":function($event){return _vm.$emit('chooseOrder', 'IMMEDIATE-CASE')}}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1 pb-0",style:({
          color: hover ? 'white' : '#147BD1'
        })},[_c('strong',[_vm._v(" IMMEDIATE NEXUS 360° ")])]),_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/immediate-nexus-360.png'),"position":"center center"}})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }