<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path
      :fill="color"
      stroke-miterlimit="10"
      stroke-width="2px"
      d="M6.38,47.43c.07,0,.14,0,.21,0V11.97c0-2.03,1.65-3.68,3.68-3.68h79.47c2.03,0,3.68,1.65,3.68,3.68v37.6c.28.02.55.02.83.04,0,0,.02,0,.02,0,1.21.08,2.24-.88,2.24-2.1V11.97c0-3.74-3.03-6.78-6.78-6.78H10.26c-3.74,0-6.78,3.03-6.78,6.78v33.32c0,1.16.94,2.11,2.1,2.13.26,0,.53,0,.79.01Z"
    />
    <path
      :fill="color"
      stroke-miterlimit="10"
      stroke-width="2px"
      d="M94.25,42.19l-.83.78v23.16c0,1.28-1.04,2.33-2.33,2.33H8.91c-1.28,0-2.33-1.04-2.33-2.33v-25.35c0-.42-.34-.77-.76-.77-.52,0-1.03-.02-1.55-.03-.43,0-.79.34-.79.78v25.38c0,3,2.43,5.43,5.43,5.43h32.56c.43,0,.78.35.78.78v12.4c0,.43-.35.78-.78.78h-9.3c-3,0-5.43,2.43-5.43,5.43v1.55c0,1.28,1.04,2.33,2.33,2.33h41.86c1.28,0,2.33-1.04,2.33-2.33v-1.55c0-3-2.43-5.43-5.43-5.43h-9.3c-.43,0-.78-.35-.78-.78v-12.4c0-.43.35-.78.78-.78h32.56c3,0,5.43-2.43,5.43-5.43v-23.06c0-.41-.32-.75-.73-.77-.51-.03-1.03-.07-1.54-.1ZM46.12,71.55h7.75c.43,0,.78.35.78.78v12.4c0,.43-.35.78-.78.78h-7.75c-.43,0-.78-.35-.78-.78v-12.4c0-.43.35-.78.78-.78ZM67.83,88.61c1.28,0,2.33,1.04,2.33,2.33h0c0,.43-.35.78-.78.78H30.62c-.43,0-.78-.35-.78-.78h0c0-1.28,1.04-2.33,2.33-2.33h35.66Z"
    />
    <path
      :fill="color"
      d="M14.34,22.73c.86,0,1.55-.69,1.55-1.55v-4.65h4.65c.86,0,1.55-.69,1.55-1.55s-.69-1.55-1.55-1.55h-6.2c-.86,0-1.55.69-1.55,1.55v6.2c0,.86.69,1.55,1.55,1.55Z"
    />
    <path
      :fill="color"
      d="M79.46,16.72h4.65v4.65c0,.86.69,1.55,1.55,1.55s1.55-.69,1.55-1.55v-6.2c0-.86-.69-1.55-1.55-1.55h-6.2c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55Z"
    />
    <path
      :fill="color"
      d="M79.46,63.33h6.2c.86,0,1.55-.69,1.55-1.55v-6.2c0-.86-.69-1.55-1.55-1.55s-1.55.69-1.55,1.55v4.65h-4.65c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55Z"
    />
    <path
      :fill="color"
      d="M14.34,63.33h6.2c.86,0,1.55-.69,1.55-1.55s-.69-1.55-1.55-1.55h-4.65v-4.65c0-.86-.69-1.55-1.55-1.55s-1.55.69-1.55,1.55v6.2c0,.86.69,1.55,1.55,1.55Z"
    />
    <g>
      <path
        :fill="color"
        d="M29.73,43.66l5.51-16.95c.08-.23.29-.39.54-.39h3.42c.39,0,.67.39.53.76l-8.34,23.17c-.08.23-.29.38-.53.38h-3.29c-.24,0-.45-.15-.53-.38l-8.31-23.17c-.13-.37.14-.76.53-.76h3.4c.25,0,.47.16.54.39l5.44,16.94c.17.52.91.53,1.08,0Z"
      />
      <path
        :fill="color"
        d="M55.45,44.36c0-1.07-.38-1.89-1.13-2.47-.75-.58-2.11-1.16-4.06-1.75-1.96-.59-3.52-1.25-4.67-1.97-2.21-1.39-3.32-3.21-3.32-5.44,0-1.96.8-3.57,2.4-4.84,1.6-1.27,3.67-1.9,6.22-1.9,1.69,0,3.2.31,4.52.93,1.32.62,2.36,1.51,3.12,2.66.65.99,1.02,2.07,1.11,3.24.03.32-.25.59-.57.59h-3.07c-.3,0-.53-.24-.56-.54-.1-1.01-.49-1.83-1.17-2.44-.8-.72-1.93-1.08-3.41-1.08-1.38,0-2.45.3-3.21.88-.76.59-1.14,1.41-1.14,2.47,0,.89.41,1.63,1.24,2.23s2.18,1.17,4.07,1.74,3.41,1.2,4.56,1.92c1.15.72,1.99,1.54,2.52,2.47.53.93.8,2.02.8,3.26,0,2.03-.78,3.64-2.33,4.83-1.55,1.2-3.66,1.79-6.32,1.79-1.76,0-3.37-.33-4.85-.98-1.47-.65-2.62-1.55-3.44-2.7-.71-.99-1.11-2.13-1.21-3.41-.02-.32.25-.6.57-.6h3.09c.3,0,.54.24.57.54.11,1.12.55,2.01,1.34,2.66.91.76,2.22,1.13,3.92,1.13,1.47,0,2.57-.3,3.31-.89.74-.6,1.11-1.38,1.11-2.36Z"
      />
      <path
        :fill="color"
        d="M63.38,50.06v-23.17c0-.31.25-.57.57-.57h6.61c2.15,0,4.05.48,5.72,1.43s2.95,2.31,3.86,4.07c.91,1.76,1.37,3.77,1.37,6.04v1.22c0,2.3-.46,4.33-1.38,6.08s-2.23,3.09-3.92,4.04c-1.7.95-3.64,1.42-5.83,1.42h-6.43c-.31,0-.57-.25-.57-.57ZM67.6,30.29v16.39c0,.31.25.57.57.57h2.19c2.21,0,3.91-.69,5.1-2.08,1.18-1.39,1.79-3.37,1.81-5.97v-1.35c0-2.64-.57-4.65-1.72-6.04-1.15-1.39-2.81-2.09-4.99-2.09h-2.39c-.31,0-.57.25-.57.57Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "VirtualSmileDesign",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
