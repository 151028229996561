<template>
  <v-container fluid v-if="!isUnknown" style="position: relative">
    <div :style="style" ref="modelContainer" />
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      :size="heightInt / 2"
      style="
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      "
    />
  </v-container>
</template>

<script>
import Vue from "vue"
import { ModelPly, ModelStl } from "vue-3d-model"
import { mapGetters, mapActions } from "vuex"

export default {
  props: ["filename", "url", "height", "width"],

  watch: {
    url(newValue, previousValue) {
      if (newValue === previousValue) return
      this.loaded = false
      this.$refs.modelContainer.innerHTML = ""
      this.mountModelComponent()
      this.resizeModelComponent()
    }
  },

  data() {
    return {
      loaded: false,
      modelComponent: null,
      lights: [
        {
          type: "HemisphereLight",
          position: { x: 0, y: 1, z: 0 },
          skyColor: 0xffffff,
          groundColor: 0x806060,
          intensity: 0.5
        },
        {
          type: "DirectionalLight",
          position: { x: 0, y: -10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: "DirectionalLight",
          position: { x: 0, y: 10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: "DirectionalLight",
          position: { x: 10, y: -10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: "DirectionalLight",
          position: { x: -10, y: 10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["modelViewerCache"]),

    style() {
      return {
        height: `${this.heightInt}px`,
        width: `${this.widthInt}px`
      }
    },

    heightInt() {
      return parseInt(this.height)
    },

    widthInt() {
      return parseInt(this.width)
    },

    isSTLFile() {
      if (!this.filename) {
        return false
      }
      if (this.filename.toLowerCase().includes(".stl")) {
        return true
      }
      return false
    },

    isPLYFile() {
      if (!this.filename) {
        return false
      }
      if (this.filename.toLowerCase().includes(".ply")) {
        return true
      }
      return false
    },

    isUnknown() {
      return !this.isPLYFile && !this.isSTLFile
    }
  },

  methods: {
    ...mapActions(["setModelViewerCache"]),

    setLoaded() {
      this.loaded = true
    },

    mountModelComponent() {
      for (const cacheItem of this.modelViewerCache) {
        if (cacheItem.filename === this.filename) {
          this.$refs.modelContainer.appendChild(cacheItem.component.$el)
          this.modelComponent = cacheItem.component
          this.setLoaded()
          return
        }
      }

      if (this.isSTLFile) {
        const ModelStlClass = Vue.extend(ModelStl)
        const stlComponent = new ModelStlClass({
          propsData: {
            width: this.widthInt,
            height: this.heightInt,
            lights: this.lights,
            src: this.url
          }
        })
        stlComponent.$mount()
        stlComponent.$on("on-load", () => this.setLoaded())
        this.setModelViewerCache({
          filename: this.filename,
          component: stlComponent
        })
        this.modelComponent = stlComponent
        this.$refs.modelContainer.appendChild(stlComponent.$el)
      }

      if (this.isPLYFile) {
        const ModelPlyClass = Vue.extend(ModelPly)
        const plyComponent = new ModelPlyClass({
          propsData: {
            width: this.widthInt,
            height: this.heightInt,
            lights: this.lights,
            src: this.url
          }
        })
        plyComponent.$mount()
        plyComponent.$on("on-load", () => this.setLoaded())
        this.setModelViewerCache({
          filename: this.filename,
          component: plyComponent
        })
        this.modelComponent = plyComponent
        this.$refs.modelContainer.appendChild(plyComponent.$el)
      }
    },

    resizeModelComponent() {
      if (!this.modelComponent) return
      this.modelComponent.$refs.canvas.width = this.widthInt
      this.modelComponent.$refs.canvas.height = this.heightInt
      this.modelComponent.$refs.canvas.style = `width: ${this.widthInt}px; height: ${this.heightInt}px;`
      this.modelComponent.size = {
        width: this.widthInt,
        height: this.heightInt
      }
      this.modelComponent.update()
    }
  },

  mounted() {
    this.mountModelComponent()
    this.resizeModelComponent()
  }
}
</script>
