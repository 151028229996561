<template>
  <v-hover v-slot:default="{ hover }" :disabled="disabled">
    <div>
      <v-card
        flat
        height="175"
        width="175"
        :class="[
          'rounded-10',
          'blue-border',
          { 'btn-primary': hover, 'disabled-button': disabled }
        ]"
        @click="$emit('chooseOrder', 'IMMEDIATE-CASE')"
      >
        <v-card-text
          class="px-1 text-center text-subtitle-1 pb-0"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          <strong> IMMEDIATE </strong>
        </v-card-text>
        <v-container fluid>
          <v-img
            contain
            :src="require('@/assets/immediate-bridge-on-tibase.png')"
            position="center center"
          />
        </v-container>
      </v-card>
      <v-card flat v-if="hover" width="200px">
        <v-card-text class="text-left pa-2">
          <ul>
            <li>
              If your implant surgery has been completed, order your Immediate
              Restoration here.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "ImmediateButton",

  props: ["disabled"]
}
</script>

<style scoped>
.disabled-button {
  cursor: not-allowed;
  pointer-events: none;
  filter: grayscale(10%) brightness(80%);
  opacity: 0.5;
}
</style>
