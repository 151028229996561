<template>
  <v-card flat>
    <v-card-title class="pl-0">
      <v-icon color="error" v-if="error" class="mr-2">mdi-close-circle</v-icon>
      Do you have Gauge Scans available?
    </v-card-title>
    <v-card-text class="pl-0">
      <v-btn-toggle v-model="gaugeScans" class="rounded-10">
        <v-btn :value="true">
          <v-icon v-if="gaugeScans === true" color="primary" class="mr-2">
            mdi-check-circle
          </v-icon>
          <v-icon v-if="!gaugeScans" color="secondary" class="mr-2">
            mdi-circle
          </v-icon>
          Yes
        </v-btn>
        <v-btn :value="false">
          <v-icon v-if="gaugeScans === false" color="primary" class="mr-2">
            mdi-check-circle
          </v-icon>
          <v-icon
            v-if="gaugeScans === undefined || gaugeScans === true"
            color="secondary"
            class="mr-2"
          >
            mdi-circle
          </v-icon>
          No
        </v-btn>
      </v-btn-toggle>
    </v-card-text>
    <v-fade-transition>
      <span class="error--text" v-if="error">
        {{ this.error }}
      </span>
    </v-fade-transition>
  </v-card>
</template>

<script>
export default {
  name: "NewOrderHasGaugeScans",

  props: ["value"],

  inject: ["form"],

  data() {
    return {
      error: null,
      valid: true
    }
  },

  created() {
    this.form.register(this)
  },

  computed: {
    gaugeScans: {
      get() {
        return this.value
      },

      set(value) {
        if (value) this.error = null
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validate() {
      if (this.gaugeScans === undefined) {
        this.error = "Required"
        this.valid = false
      } else {
        this.valid = true
      }

      return this.valid
    }
  }
}
</script>
