<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100">
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.02,80.95h0c.45.24.96.37,1.48.37.64,0,1.26-.19,1.79-.55l10.12-6.94c.86-.59,1.38-1.57,1.38-2.61s-.52-2.02-1.38-2.61l-10.12-6.94c-.97-.66-2.22-.74-3.27-.19-1.04.55-1.69,1.62-1.69,2.8v1.55l-6.08-4.17c-.97-.67-2.22-.74-3.26-.19-1.04.55-1.69,1.62-1.69,2.8v13.88c0,1.18.65,2.25,1.69,2.8,1.02.54,2.31.47,3.27-.19l6.08-4.17v1.55c0,1.18.65,2.25,1.69,2.8ZM53.33,69.01v-3.15l7.82,5.36-7.82,5.36v-7.56ZM42.3,76.57v-10.71l7.82,5.36-7.82,5.36Z"
    />
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68.99,80.66c0,2.47-2,4.47-4.47,4.47H10.86c-2.47,0-4.47-2-4.47-4.47V19.34c0-2.47,2-4.47,4.47-4.47h53.66c2.47,0,4.47,2,4.47,4.47v12.78h6.39v-12.78c0-6-4.86-10.86-10.86-10.86H10.86C4.86,8.48,0,13.34,0,19.34v61.33c0,6,4.86,10.86,10.86,10.86h53.66c6,0,10.86-4.86,10.86-10.86v-28.11h-6.39v28.11Z"
    />
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M99.54,40.53l-16.26-16.26c-1-1-2.62-1-3.61,0-1,1-1,2.62,0,3.61l11.9,11.9h-34.71c-1.41,0-2.56,1.14-2.56,2.56s1.14,2.56,2.56,2.56h34.71l-11.9,11.9c-1,1-1,2.62,0,3.61,1,1,2.62,1,3.61,0l16.26-16.26c1-1,1-2.62,0-3.61Z"
    />
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.62,45.08c1.76-5.08,3.1-11.36,3.1-16.05,0-5.11-4.26-9.26-9.26-9.26-2.81,0-4.95,1.71-6.24,2.75h0c-.11.09-.22.18-.32.26-.59.47-1.02.82-1.44,1.08-.43.27-.61.29-.62.29-.02,0-.19-.02-.62-.29-.42-.27-.85-.61-1.44-1.08-.1-.08-.21-.17-.32-.25h0c-1.29-1.04-3.43-2.75-6.24-2.75-4.96,0-9.26,3.96-9.26,9.26,0,4.69,1.34,10.97,3.1,16.05.88,2.55,1.92,4.93,3.04,6.72.55.89,1.19,1.74,1.92,2.4.69.63,1.76,1.34,3.12,1.34.91,0,1.63-.36,2.1-.67.48-.32.88-.7,1.2-1.04.49-.53,1.01-1.21,1.45-1.78.1-.13.2-.26.29-.37.56-.72,1.01-1.26,1.42-1.62.11-.09.19-.16.25-.2.06.04.15.11.25.2.41.36.86.9,1.42,1.62.09.12.19.24.29.37.44.57.96,1.25,1.45,1.78.32.34.72.73,1.19,1.04.47.31,1.19.67,2.1.67,1.36,0,2.43-.71,3.12-1.34.73-.66,1.36-1.51,1.92-2.4,1.12-1.8,2.16-4.18,3.04-6.72ZM36.79,43.4c-.82,2.36-1.71,4.35-2.55,5.7-.22.35-.42.64-.59.85-.21-.26-.44-.56-.71-.91-.12-.16-.25-.32-.39-.5-.56-.72-1.27-1.61-2.09-2.33-.79-.7-2.03-1.53-3.62-1.53s-2.83.84-3.62,1.53c-.82.72-1.54,1.61-2.09,2.33-.14.18-.27.35-.39.5-.27.35-.5.65-.71.91-.17-.22-.37-.5-.59-.85-.84-1.35-1.73-3.34-2.55-5.7-1.65-4.74-2.82-10.44-2.82-14.37,0-2.37,1.9-4.15,4.15-4.15.79,0,1.61.47,3.05,1.63.08.07.17.14.27.21.57.46,1.27,1.02,1.96,1.46.83.52,1.97,1.08,3.34,1.08s2.51-.55,3.34-1.08c.7-.44,1.4-1,1.96-1.46.09-.07.18-.15.27-.21,1.44-1.16,2.26-1.63,3.05-1.63,2.21,0,4.15,1.9,4.15,4.15,0,3.93-1.17,9.63-2.82,14.37Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ImmediateDesign",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
