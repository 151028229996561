export default [
  {
    product: "DESIGN",
    title: "Design Only Order",
    description: "Design Only",
    image: require("@/assets/design-only-prosthesis.png"),
    caseType: "direct",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: true,
      multiUnitConfirmation: true,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      existingProsthesisInSituScan: {
        title: "Existing Prosthesis",
        subtitle: "In-Situ Scan",
        fileType: "scan",
        value: "existing_prosthesis_in_situ_scan",
        intraoral: true,
        optional: false,
        multiple: false
      },
      tissueOfRestoringArchUsingNarrowScanBodies: {
        title: "Tissue of Restoring Arch",
        subtitle: "Using Narrow Scan Bodies",
        fileType: "scan",
        value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
        intraoral: true,
        optional: false,
        multiple: false
      },
      existingProsthesisExtraOrally360UsingScanAnalogs: {
        title: "Existing Prosthesis Extra-Orally 360",
        subtitle: "Using Scan Analogs",
        fileType: "scan",
        value: "existing_prosthesis_extra_orally_360_using_scan_analogs",
        intraoral: true,
        optional: false,
        multiple: false
      },
      antagonistDentition: {
        title: "Antagonist Dentition",
        subtitle: "",
        fileType: "scan",
        value: "antagonist_dentition",
        intraoral: true,
        optional: false,
        multiple: false
      },
      biteScan: {
        title: "Bite Scan",
        subtitle: "",
        fileType: "scan",
        value: "bite_scan",
        intraoral: true,
        optional: true,
        multiple: true
      },
      fullFaceSmilingWithRelaxedBite: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite",
        optional: false,
        multiple: false
      },
      fullFaceSmilingWithRelaxedBiteUsingRetractors: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite using Retractors",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite_using_retractors",
        optional: false,
        multiple: false
      }
    }
  },
  {
    product: "BAR",
    title: "Bar Only Order",
    description: "Bar Only",
    image: require("@/assets/milled-bar-only.png"),
    caseType: "direct",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: false,
      multiUnitConfirmation: true,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      toothDesign: {
        title: "Tooth Design",
        subtitle: null,
        fileType: "scan",
        value: "tooth_design",
        intraoral: true,
        optional: false,
        multiple: false
      },
      tissueOfRestoringArchUsingNarrowScanBodies: {
        title: "Tissue of Restoring Arch",
        subtitle: "Using Narrow Scan Bodies",
        fileType: "scan",
        value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
        intraoral: true,
        optional: true,
        multiple: false
      },
      constructionFile: {
        title: "Construction File",
        subtitle: "",
        fileType: "other",
        value: "construction_file",
        intraoral: false,
        optional: true,
        multiple: true
      }
    }
  },
  {
    product: "FINISHED-CASE",
    title: "Finished Prosthesis Order",
    description: "Finished Case",
    image: require("@/assets/final-prosthesis-bar-retained.png"),
    caseType: "handover",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: false,
      multiUnitConfirmation: true,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      existingProsthesisInSituScan: {
        title: "Existing Prosthesis",
        subtitle: "In-Situ Scan",
        fileType: "scan",
        value: "existing_prosthesis_in_situ_scan",
        intraoral: true,
        optional: false,
        multiple: false
      },
      tissueOfRestoringArchUsingNarrowScanBodies: {
        title: "Tissue of Restoring Arch",
        subtitle: "Using Narrow Scan Bodies",
        fileType: "scan",
        value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
        intraoral: true,
        optional: false,
        multiple: false
      },
      existingProsthesisExtraOrally360UsingScanAnalogs: {
        title: "Existing Prosthesis Extra-Orally 360",
        subtitle: "Using Scan Analogs",
        fileType: "scan",
        value: "existing_prosthesis_extra_orally_360_using_scan_analogs",
        intraoral: true,
        optional: false,
        multiple: false
      },
      antagonistDentition: {
        title: "Antagonist Dentition",
        subtitle: "",
        fileType: "scan",
        value: "antagonist_dentition",
        intraoral: true,
        optional: false,
        multiple: false
      },
      biteScan: {
        title: "Bite Scan",
        subtitle: "",
        fileType: "scan",
        value: "bite_scan",
        intraoral: true,
        optional: true,
        multiple: true
      },
      fullFaceSmilingWithRelaxedBite: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite",
        optional: false,
        multiple: false
      },
      fullFaceSmilingWithRelaxedBiteUsingRetractors: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite using Retractors",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite_using_retractors",
        optional: false,
        multiple: false
      }
    }
  },
  {
    product: "VIRTUAL-SMILE-DESIGN",
    title: "Virtual Smile Design",
    description: "Virtual Smile Design",
    image: require("@/assets/virtual-smile-design.png"),
    caseType: "handover",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: false,
      multiUnitConfirmation: false,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      upperDentition: {
        title: "Upper Dentition",
        subtitle: "",
        fileType: "scan",
        value: "upper_dentition",
        intraoral: true,
        optional: false,
        multiple: false
      },
      lowerDentition: {
        title: "Lower Dentition",
        subtitle: "",
        fileType: "scan",
        value: "lower_dentition",
        intraoral: true,
        optional: false,
        multiple: false
      },
      biteScan: {
        title: "Bite Scan",
        subtitle: "",
        fileType: "scan",
        value: "bite_scan",
        intraoral: true,
        optional: true,
        multiple: true
      },
      fullFaceSmilingWithRelaxedBite: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite",
        optional: false,
        multiple: false
      },
      fullFaceSmilingWithRelaxedBiteUsingRetractors: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite using Retractors",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite_using_retractors",
        optional: false,
        multiple: false
      }
    }
  },
  {
    product: "IMMEDIATE-DESIGN",
    title: "Immediate Design",
    description: "Immediate Design",
    image: require("@/assets/immediate-design-only.png"),
    caseType: "handover",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: false,
      multiUnitConfirmation: true,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      tissueOfRestoringArch: {
        title: "Tissue of Restoring Arch",
        subtitle: "",
        fileType: "scan",
        value: "tissue_of_restoring_arch",
        intraoral: true,
        optional: true,
        multiple: false
      },
      tissueOfRestoringArchUsingNarrowScanBodies: {
        title: "Tissue of Restoring Arch",
        subtitle: "Using Narrow Scan Bodies",
        fileType: "scan",
        value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
        intraoral: true,
        optional: true,
        multiple: false
      },
      midGaugeScan: {
        title: "Mid Gauge Scan",
        subtitle: "",
        fileType: "scan",
        value: "mid_gauge_scan",
        intraoral: true,
        optional: true,
        multiple: false
      },
      sstDenture360: {
        title: "SST Denture 360",
        subtitle: "",
        fileType: "scan",
        value: "sst_denture_360",
        intraoral: true,
        optional: true,
        multiple: false
      },
      biteScan: {
        title: "Bite Scan",
        subtitle: "",
        fileType: "scan",
        value: "bite_scan",
        intraoral: true,
        optional: true,
        multiple: true
      },
      fullFaceSmilingWithRelaxedBite: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite",
        optional: true,
        multiple: false
      },
      fullFaceSmilingWithRelaxedBiteUsingRetractors: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite using Retractors",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite_using_retractors",
        optional: true,
        multiple: false
      }
    }
  },
  {
    product: "IMMEDIATE-BRIDGE",
    title: "Immediate Bridge on Ti Base",
    description: "Immediate Bridge on Ti Base",
    image: require("@/assets/immediate-bridge-on-tibase.png"),
    caseType: "handover",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: false,
      multiUnitConfirmation: true,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      tissueOfRestoringArch: {
        title: "Tissue of Restoring Arch",
        subtitle: "",
        fileType: "scan",
        value: "tissue_of_restoring_arch",
        intraoral: true,
        optional: true,
        multiple: false
      },
      tissueOfRestoringArchUsingNarrowScanBodies: {
        title: "Tissue of Restoring Arch",
        subtitle: "Using Narrow Scan Bodies",
        fileType: "scan",
        value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
        intraoral: true,
        optional: true,
        multiple: false
      },
      midGaugeScan: {
        title: "Mid Gauge Scan",
        subtitle: "",
        fileType: "scan",
        value: "mid_gauge_scan",
        intraoral: true,
        optional: true,
        multiple: false
      },
      sstDenture360: {
        title: "SST Denture 360",
        subtitle: "",
        fileType: "scan",
        value: "sst_denture_360",
        intraoral: true,
        optional: true,
        multiple: false
      },
      biteScan: {
        title: "Bite Scan",
        subtitle: "",
        fileType: "scan",
        value: "bite_scan",
        intraoral: true,
        optional: true,
        multiple: true
      },
      fullFaceSmilingWithRelaxedBite: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite",
        optional: true,
        multiple: false
      },
      fullFaceSmilingWithRelaxedBiteUsingRetractors: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite using Retractors",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite_using_retractors",
        optional: true,
        multiple: false
      }
    }
  },
  {
    product: "IMMEDIATE-NEXUS-360",
    title: "Immediate Nexus 360°",
    description: "Immediate Nexus 360°",
    image: require("@/assets/immediate-nexus-360.png"),
    caseType: "handover",
    widgets: {
      foSessionOption: true,
      connectionTypeSelector: false,
      multiUnitConfirmation: true,
      specialRequests: true,
      uploadProjectFiles: true
    },
    fileCategories: {
      tissueOfRestoringArch: {
        title: "Tissue of Restoring Arch",
        subtitle: "",
        fileType: "scan",
        value: "tissue_of_restoring_arch",
        intraoral: true,
        optional: true,
        multiple: false
      },
      tissueOfRestoringArchUsingNarrowScanBodies: {
        title: "Tissue of Restoring Arch",
        subtitle: "Using Narrow Scan Bodies",
        fileType: "scan",
        value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
        intraoral: true,
        optional: true,
        multiple: false
      },
      midGaugeScan: {
        title: "Mid Gauge Scan",
        subtitle: "",
        fileType: "scan",
        value: "mid_gauge_scan",
        intraoral: true,
        optional: true,
        multiple: false
      },
      sstDenture360: {
        title: "SST Denture 360",
        subtitle: "",
        fileType: "scan",
        value: "sst_denture_360",
        intraoral: true,
        optional: true,
        multiple: false
      },
      biteScan: {
        title: "Bite Scan",
        subtitle: "",
        fileType: "scan",
        value: "bite_scan",
        intraoral: true,
        optional: true,
        multiple: true
      },
      fullFaceSmilingWithRelaxedBite: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite",
        optional: true,
        multiple: false
      },
      fullFaceSmilingWithRelaxedBiteUsingRetractors: {
        title: "Full-Face Smiling",
        subtitle: "With Relaxed Bite using Retractors",
        fileType: "photo",
        value: "full_face_smiling_with_relaxed_bite_using_retractors",
        optional: true,
        multiple: false
      }
    }
  }
]
