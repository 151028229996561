<template>
  <v-row>
    <v-col
      :cols="
        selectedForwardedOrder?.submitted_at ||
        selectedForwardedOrder?.oep_submission_uid
          ? 6
          : 12
      "
    >
      <v-card flat class="rounded-20 mx-4">
        <v-row class="ma-0">
          <v-col cols="8" class="pa-0">
            <v-card-title class="title-font">
              <v-icon large class="mr-2">{{
                determineIconFromProduct(currentFoParams.product)
              }}</v-icon>
              {{ currentFoParams.title }}
              <v-progress-circular class="ml-5" v-if="loading" indeterminate />
            </v-card-title>
            <v-card-text
              v-if="
                !selectedForwardedOrder?.submitted_at &&
                !selectedForwardedOrder?.oep_submission_uid
              "
            >
              Continue your order by completing the necessary options.
            </v-card-text>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-card-text
              class="fill-width d-flex justify-center pb-0"
              v-if="currentFoParams.image"
            >
              <v-img
                :src="currentFoParams.image"
                class="mx-4"
                contain
                max-width="200"
                max-height="100"
              />
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-text class="pt-0">
          <v-card-text
            v-if="
              currentFoParams.caseType === 'handover' &&
              selectedForwardedOrder?.oep_submission_uid
            "
            class="mt-2"
          >
            Continue your order on the Nexus IOS ordering platform. Once
            submitted, the order details will be displayed below.
            <v-card-text>
              <v-card flat outlined class="rounded-10 pa-2 mb-3">
                <v-row>
                  <v-col cols="12">
                    <v-icon size="30" class="mr-2">
                      {{
                        determineIconFromProduct(selectedForwardedOrder.product)
                      }}
                    </v-icon>
                    {{ toTitleCase(selectedForwardedOrder.product) }}
                    <v-chip label small color="primary" class="px-1 ml-2">{{
                      sessionName(selectedForwardedOrder.session_uid)
                    }}</v-chip>
                  </v-col>
                  <v-col cols="6">
                    {{
                      selectedForwardedOrder.created_at
                        | luxon({ output: "short" })
                    }}
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      class="btn-primary mr-2 pa-2"
                      @click="openNexusOrderingCase"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card-text>
          <OrderSummary v-if="selectedForwardedOrder?.submitted_at" />
        </v-card-text>

        <div
          v-if="
            !selectedForwardedOrder?.submitted_at &&
            !selectedForwardedOrder?.oep_submission_uid
          "
        >
          <ForwardedOrderOptions
            @back="$emit('back')"
            @next="handleStepChange(++workflowStep)"
            :foParams="currentFoParams"
          />
        </div>
      </v-card>
    </v-col>
    <v-col
      v-if="
        selectedForwardedOrder?.submitted_at ||
        selectedForwardedOrder?.oep_submission_uid
      "
    >
      <CaseChat class="mr-4" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ForwardedOrderOptions from "./ForwardedOrderComponent/ForwardedOrderOptions"
import OrderSummary from "./ForwardedOrderComponent/OrderSummary"
import CaseChat from "@/components/shared/CaseChat"
import client from "@/lib/ApiClient"
import forwardOrderParams from "./forward_order_params"
import { toTitleCase, determineIconFromProduct } from "@/utils"

export default {
  name: "ForwardedOrderComponent",

  components: {
    CaseChat,
    ForwardedOrderOptions,
    OrderSummary
  },

  data() {
    return {
      loading: false,
      workflowStep: 1,
      dialog: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "paymentMethods",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "selectedOrderExports"
    ]),

    currentStep() {
      return this.exportComplete ? 2 : 1
    },

    exportComplete() {
      return !!this.selectedOrderExports?.length
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find(
        (s) => s.product_code === this.currentFoParams.product
      ).method
    },

    currentFoParams() {
      return forwardOrderParams.find(
        (params) => params.product === this.forwardedOrderForm.product_code
      )
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "setPaymentMethods"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    sessionName(sessionUid) {
      return toTitleCase(
        this.selectedOrder.sessions.find((s) => s.uid === sessionUid)
          .session_type
      )
    },

    async openNexusOrderingCase() {
      const forwardedOrder = await client.instance.orders.getForwardedOrder(
        this.selectedOrder.uid,
        this.selectedForwardedOrder.session_uid,
        this.selectedForwardedOrder.uid
      )
      if (forwardedOrder.oep_submission_uid && !forwardedOrder.submitted_at) {
        window.open(
          `${process.env.VUE_APP_NEXUS_ORDERING_URL}/${forwardedOrder.oep_submission_uid}`,
          "_blank"
        )
      } else {
        this.setSelectedForwardedOrder(forwardedOrder)
        this.handleStepChange(3)
      }
    },

    async tabRefocus() {
      if (
        document.visibilityState === "visible" &&
        this.selectedForwardedOrder
      ) {
        this.loading = true
        const forwardedOrder = await client.instance.orders.getForwardedOrder(
          this.selectedOrder.uid,
          this.selectedForwardedOrder.session_uid,
          this.selectedForwardedOrder.uid
        )
        if (forwardedOrder.submitted_at) {
          this.setSelectedForwardedOrder(forwardedOrder)
          this.handleStepChange(3)
        }
        this.loading = false
      }
    },

    toTitleCase,

    determineIconFromProduct
  },

  async mounted() {
    document.addEventListener("visibilitychange", this.tabRefocus)
  },

  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.tabRefocus)
  }
}
</script>
