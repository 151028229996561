<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="7.29102"
      y="5.8335"
      width="20.4167"
      height="24.7917"
      rx="2"
      :stroke="color"
      stroke-width="2.2"
    />
    <path
      d="M13.125 13.125H21.875"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M13.125 18.9585H21.875"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M13.125 24.7915H18.9583"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "OrderIcon",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
