<template>
  <v-card flat class="card-bg rounded-20 mt-5 pb-5">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font">
        TRANSACTION HISTORY
      </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mx-4 rounded-10">
      <v-card-text v-if="loading" class="text-center">
        <v-progress-circular indeterminate color="primary" />
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="quotaHistory || []"
        :items-per-page="10"
        v-if="!loading"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ displayedDateTime(item.created_at) }}
        </template>
        <template v-slot:[`item.entry_type`]="{ item }">
          {{ toTitleCase(item.entry_type) }}
        </template>
        <template v-slot:[`item.quota_code`]="{ item }">
          {{ toTitleCase(item.quota_code) }}
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          {{ item.quantity }}
        </template>
        <template v-slot:[`item.order_number`]="{ item }">
          <v-btn
            color="primary"
            text
            :to="{
              name: 'caseView',
              params: { uid: item.order_uid }
            }"
            v-if="item.order_uid"
            small
          >
            {{ orderNumber(item.order_uid) }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { toTitleCase } from "@/utils"

export default {
  name: "QuotaHistory",

  data() {
    return {
      loading: false,
      filter: null,
      headers: [
        {
          text: "Date",
          value: "created_at"
        },
        {
          text: "Type",
          value: "entry_type"
        },
        {
          text: "Quota",
          value: "quota_code"
        },
        {
          text: "Quantity",
          value: "quantity"
        },
        {
          text: "Order Number",
          value: "order_number",
          align: "center"
        }
      ]
    }
  },

  watch: {
    quotaHistory(val) {
      if (val) this.loading = false
    },

    filter() {
      this.$forceUpdate()
    }
  },

  computed: {
    ...mapGetters(["quotaHistory", "inboxItems"])
  },

  methods: {
    ...mapActions(["triggerReload"]),

    toTitleCase,

    orderNumber(orderUid) {
      if (!this.inboxItems || !orderUid) return "-"

      const order = this.inboxItems.find((o) => o.uid === orderUid)
      if (order) return order.order_number
      return "-"
    },

    displayedDateTime(timeStamp) {
      return new Date(timeStamp).toLocaleString()
    }
  },

  mounted() {
    this.loading = true
    if (this.quotaHistory) this.loading = false
    else this.triggerReload("quotaHistory")
  }
}
</script>
