<template>
  <div>
    <v-form ref="orderForm" lazy-validation>
      <v-row>
        <v-col cols="6">
          <FoSessionOption v-if="foParams.widgets.foSessionOption" />
          <ConnectionTypeSelector
            v-if="foParams.widgets.connectionTypeSelector"
            v-model="localForwardedOrderForm.order_form.connection_type"
          />
          <MultiUnitConfirmation
            v-if="foParams.widgets.multiUnitConfirmation"
            v-model="localForwardedOrderForm.implants"
            :interactive="true"
            :validate-form="foParams.caseType === 'direct'"
          />
          <SpecialRequests
            v-if="foParams.widgets.specialRequests"
            v-model="localForwardedOrderForm.special_requests"
          />
        </v-col>
        <v-col cols="6">
          <UploadProjectFiles
            v-if="foParams.widgets.uploadProjectFiles"
            :foParams="foParams"
            v-model="files"
          />
          <v-row>
            <v-col>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="rounded-10 btn-error"
                  elevation="0"
                  @click="$emit('back')"
                >
                  Go Back
                </v-btn>
                <v-btn
                  class="btn-primary my-4 mx-4"
                  @click="handleContinue"
                  :disabled="!isOwner && !designsAvailable"
                >
                  {{
                    foParams.caseType === "handover"
                      ? "Continue Order"
                      : "Send Order"
                  }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <PurchaseDialog
      v-model="dialog"
      :item="lineItem"
      :acquisitionMethod="acquisitionMethod"
      :loading="loading"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepCopy } from "@/utils"
import client from "@/lib/ApiClient"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation.vue"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import ConnectionTypeSelector from "./ForwardedOrderOptions/ConnectionTypeSelector.vue"
import UploadProjectFiles from "./ForwardedOrderOptions/UploadProjectFiles.vue"
import SpecialRequests from "@/components/shared/SpecialRequests.vue"
import FoSessionOption from "../shared/FoSessionOption.vue"

export default {
  name: "ForwardedOrderOptions",

  components: {
    PurchaseDialog,
    ConnectionTypeSelector,
    MultiUnitConfirmation,
    UploadProjectFiles,
    SpecialRequests,
    FoSessionOption
  },

  props: ["foParams"],

  data() {
    return {
      dialog: false,
      loading: false,
      acquisitionStrategy: null,
      localForwardedOrderForm: {},
      files: []
    }
  },

  watch: {
    localForwardedOrderForm: {
      async handler(value) {
        if (!value || !this.selectedForwardedOrder) return
        const response = await client.instance.orders.updateForwardedOrder(
          this.selectedOrder.uid,
          this.selectedOrderSession.uid,
          this.selectedForwardedOrder.uid,
          value
        )
        this.setSelectedForwardedOrder(response)

        const orderForwardedOrdersCopy = deepCopy(this.orderForwardedOrders)
        const foIndex = orderForwardedOrdersCopy.findIndex(
          (fo) => fo.uid === this.selectedForwardedOrder.uid
        )
        orderForwardedOrdersCopy.splice(foIndex, 1, response)
        this.setOrderForwardedOrders(orderForwardedOrdersCopy)
      },
      deep: true
    },
    files: {
      handler(value) {
        this.localForwardedOrderForm.file_uids = value
          .filter((item) => item !== null)
          .map((item) => item.uid)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "isOwner",
      "country",
      "products",
      "currentPlan",
      "currentQuotas",
      "selectedOrder",
      "selectedOrderSession",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "orderForwardedOrders"
    ]),

    lineItem() {
      const product = this.products.find(
        (p) => p.product_code === this.foParams.product
      )
      const quota = this.currentQuotas.find(
        (q) => q.product_code === this.foParams.product
      )
      return {
        description: this.foParams.description,
        quantity: 1,
        currency: this.currentPlan?.subscription_currency,
        price: product?.price,
        product_code: product.product_code,
        quota_code: quota.quota_code
      }
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find(
        (s) => s.product_code === this.foParams.product
      ).method
    },

    designsAvailable() {
      const designs = this.currentQuotas.find(
        (q) => q.product_code === this.foParams.product
      )
      return designs.current
    }
  },

  methods: {
    ...mapActions([
      "triggerReload",
      "setSelectedForwardedOrder",
      "setOrderForwardedOrders"
    ]),

    async handleContinue() {
      if (!this.$refs.orderForm.validate()) {
        this.scrollToFirstInvalidField(this.$refs.orderForm)
        return
      }
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: this.foParams.product,
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        purchaseForm
      )
      this.loading = false
      this.dialog = true
    },

    async handleConfirm(paymentMethodId) {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: this.foParams.product,
            quantity: 1
          }
        ],
        payment_type: "stripe",
        payment_id: paymentMethodId,
        order_uid: this.selectedOrder.uid,
        order_session_uid: this.selectedOrderSession.uid,
        metadata: {
          forwarded_order_uid: this.selectedForwardedOrder.uid
        }
      }
      try {
        await client.gateway.billing.commitPurchase(purchaseForm)
      } catch {
        // Error States?
        console.error(`Failed to order ${this.foParams.descripiton}`)
        this.loading = false
        this.dialog = false
        return
      }

      if (this.acquisitionMethod === "quota") {
        this.triggerReload("quotas")
      }

      if (this.foParams.caseType === "handover") {
        await this.checkReady()

        window.open(
          `${process.env.VUE_APP_NEXUS_ORDERING_URL}/${this.selectedForwardedOrder.oep_submission_uid}`,
          "_blank"
        )
      } else {
        this.triggerReload("forwardedOrder")
        this.triggerReload("forwardedOrders")
        this.triggerReload("order")
        this.loading = false
        this.dialog = false
      }

      this.$emit("next")
    },

    async checkReady() {
      if (this.selectedForwardedOrder.oep_submission_uid) {
        this.triggerReload("forwardedOrder")
        this.triggerReload("forwardedOrders")
        this.triggerReload("order")
        this.loading = false
        this.dialog = false
      } else {
        this.triggerReload("forwardedOrder")
        await new Promise((resolve) => setTimeout(resolve, 1000))
        return this.checkReady()
      }
    },

    scrollToFirstInvalidField(element) {
      const children = element?.$children
      if (!children || !children?.length) return

      for (let child of children) {
        if (
          (Object.keys(child).includes("valid") && !child.valid) ||
          (Object.keys(child).includes("error") && child.error)
        ) {
          this.$nextTick(() => {
            const element = child.$el
            element.scrollIntoView({ behavior: "smooth", block: "center" })
          })
          return null
        }
        if (this.scrollToFirstInvalidField(child) === null) return null
      }
    }
  },

  beforeMount() {
    this.localForwardedOrderForm = deepCopy(this.forwardedOrderForm)
  }
}
</script>
