<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="rounded-20" persistent>
    <v-card flat>
      <v-card-title class="off">
        <span class="text-h5">Add a card</span>
      </v-card-title>

      <v-card-text class="py-5">
        <v-container>
          <v-row>
            <v-col cols="12" class="px-0">
              <StripeElementCard
                ref="elementRef"
                :pk="pk"
                @token="tokenCreated"
                @error="onError"
                hidePostalCode
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="off">
        <v-spacer></v-spacer>
        <v-btn
          class="btn-error"
          text
          :disabled="loading || processing"
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn class="btn-primary" :loading="loading || processing" @click="addCard">
          Add card
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe"
import { mapActions } from "vuex"
import client from "@/lib/ApiClient"
export default {
  name: "DeleteDialog",

  components: { StripeElementCard },

  props: ["value", "loading"],

  data() {
    return {
        processing: false,
        pk: process.env.VUE_APP_STRIPE_PK
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(payload) {
        return this.$emit("input", payload)
      }
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    addCard() {
      this.$emit("toggleLoad", true)
      this.processing = true
      this.$refs.elementRef.submit()
    },

    async tokenCreated(cardToken) {
      try {
        await client.gateway.billing.addPaymentMethod(cardToken.id)
      } catch {
        this.processing = false
        this.$emit("toggleLoad", false)
      }
      this.$refs.elementRef.clear()
      this.triggerReload("paymentMethods")
      this.processing = false
      this.dialog = false
    },

    onError() {
        this.$emit('toggleLoad', false)
        this.processing = false
    }
  }
}
</script>
