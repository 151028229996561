var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
        'rounded-10',
        'blue-border',
        { 'btn-primary': hover, 'disabled-button': _vm.disabled }
      ],attrs:{"height":"175","width":"175"},on:{"click":function($event){return _vm.$emit('chooseOrder', 'DESIGN')}}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1 pb-0",style:({
          color: hover ? 'white' : '#147BD1'
        })},[_c('strong',[_vm._v(" MILLED BAR ")])]),_c('v-card-text',{staticClass:"pa-0 text-center text-caption",style:({
          color: hover ? 'white' : '#147BD1'
        })},[_vm._v(" OSTEON TOOTH DESIGN OR PROVIDED TOOTH DESIGN ")]),_c('v-container',{attrs:{"fluid":""}},[(!hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis-with-bar.png'),"position":"center center"}}):_vm._e(),(hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis-with-bar-white.png'),"position":"center center"}}):_vm._e()],1)],1),(hover)?_c('v-card',{attrs:{"flat":"","width":"200px"}},[_c('v-card-text',{staticClass:"text-left pa-2"},[_c('ul',[_c('li',[_vm._v(" Design and milling of a Nexus bar to your tooth design or Osteon tooth design. ")]),_c('li',[_vm._v("Start with abutment exports to begin design on lab-side.")]),_c('li',[_vm._v("Requires final tooth design to begin bar design.")])])])],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }