var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"title-font"},[_vm._v(" CONNECTION TYPE "),_c('v-spacer'),(_vm.showErrors && !_vm.connectionType)?_c('div',{staticClass:"error--text text-body-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-close-circle")]),_c('span',[_vm._v(" Please select a Connection Type ")])],1):_vm._e()],1),_c('v-card-text',[_vm._v(" Choose which type of connection the file will be designed to. ")]),_c('v-row',{staticClass:"mx-1 mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"offset-y":"","rounded":"10"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],class:[
              'd-flex flex-row rounded-10 pa-2 fill-height btn-hover',
              { 'btn-selected': _vm.directConnection }
            ],staticStyle:{"user-select":"none","border-width":"2px"},attrs:{"outlined":""}},'v-card',attrs,false),on),[_c('v-img',{attrs:{"contain":"","src":require('@/assets/screw-comparison-rosen.png')}}),_c('v-card-text',{staticClass:"fill-height d-flex flex-column justify-center"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Direct to Multi-Unit Abutment")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" Screw Type: "),(
                    !_vm.connectionType || _vm.connectionType === 'ti_base_to_mua'
                  )?_c('span',[_vm._v(" (select from drop down) ")]):_vm._e(),(_vm.screwSelected)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.screwSelected.title)+" ")])]):_vm._e()])])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("SELECT SCREW TYPE:")]),_c('v-divider'),_vm._l((_vm.screwTypes),function(screw,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleButtonSelect(screw.value)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(screw.title)+" ")])],1)],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],class:[
          'd-flex flex-row rounded-10 pa-2 fill-height btn-hover ',
          { 'btn-selected': _vm.connectionType === 'ti_base_to_mua' }
        ],staticStyle:{"user-select":"none","border-width":"2px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.handleButtonSelect('ti_base_to_mua')}}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/screw-comparison-Ti-base.png')}}),_c('v-card-text',{staticClass:"fill-height d-flex flex-column justify-center"},[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Ti-Base to Multi-Unit Abutment")])]),_c('p',{staticClass:"mb-0"},[_vm._v("with prosthetic screw seat")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }