var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:[
        'rounded-10',
        'blue-border',
        { 'btn-primary': hover, 'disabled-button': _vm.disabled }
      ],attrs:{"flat":"","height":"175","width":"175"},on:{"click":function($event){return _vm.$emit('chooseOrder', 'DESIGN')}}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1 pb-0",style:({
          color: hover ? 'white' : '#147BD1'
        })},[_c('strong',[_vm._v(" TOOTH DESIGN ONLY ")])]),_c('v-container',{attrs:{"fluid":""}},[(!hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis.png'),"position":"center center"}}):_vm._e(),(hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis-white.png'),"position":"center center"}}):_vm._e()],1)],1),(hover)?_c('v-card',{attrs:{"flat":"","width":"200px"}},[_c('v-card-text',{staticClass:"text-left pa-2"},[_c('ul',[_c('li',[_vm._v("Full-arch bridge design.")]),_c('li',[_vm._v("Direct to MUA or Ti Base.")]),_c('li',[_vm._v("Milled or printed by you.")])])])],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }