import { render, staticRenderFns } from "./ModelViewer.vue?vue&type=template&id=fd6eb07c"
import script from "./ModelViewer.vue?vue&type=script&lang=js"
export * from "./ModelViewer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports