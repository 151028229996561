<template>
  <v-hover v-slot:default="{ hover }">
    <div>
      <v-card
        flat
        height="175"
        width="175"
        :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
        @click="$emit('chooseOrder', 'IMMEDIATE-CASE')"
      >
        <v-card-text
          class="px-1 text-center text-subtitle-1 pb-0"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          <strong> IMMEDIATE BRIDGE ON TI BASE</strong>
        </v-card-text>
        <v-container fluid class="pt-0">
          <v-img
            contain
            :src="require('@/assets/immediate-bridge-on-tibase.png')"
            position="center center"
          />
        </v-container>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "ImmediateDesignButton"
}
</script>
