<template>
  <v-hover v-slot:default="{ hover }">
    <div>
      <v-card
        flat
        height="175"
        width="175"
        :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
        @click="$emit('chooseOrder', 'IMMEDIATE-CASE')"
      >
        <v-card-text
          class="px-1 text-center text-subtitle-1 pb-0"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          <strong> VIRTUAL SMILE DESIGN (VSD) </strong>
        </v-card-text>
        <v-container class="pt-0" fluid>
          <v-img
            contain
            :src="require('@/assets/virtual-smile-design.png')"
            position="center center"
            max-height="90"
          />
        </v-container>
      </v-card>
      <v-card v-if="hover" flat width="200px">
        <v-card-text class="text-left pa-2">
          <ul>
            <li>Book surgery date.</li>
            <li>Select Immediate Restoration.</li>
            <li>A VSD must be ordeed during the Pre-Op stage.</li>
            <li>
              The VSD must be reviewed, and approved before ordering the
              immediate prosthesis on the day of surgery.
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "VSDButton"
}
</script>
